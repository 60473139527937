import * as firebase from 'firebase/app'
import * as qrcode from 'qrcode'
import React, { useEffect, useState } from 'react'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

const QRCode = ({ club, shortUrl, size, transparent }) => {
	const [genning, setGenning] = useState(true)
	const [qr, setQr] = useState('')
	const padding = 5

	useEffect(() => {
		const url =
			window.location.protocol +
			'//' +
			window.location.host +
			(shortUrl ? '/' + club : '/c/' + club)

		qrcode
			.toDataURL(url, {
				scale: 20,
				margin: 1,
				color: {
					light: transparent ? '#ffffff00' : '#ffffffff'
				}
			})
			.then(res => {
				setQr(res)
				setGenning(false)
			})
	}, [club, shortUrl, transparent])

	return (
		<>
			<Dimmer active={genning}>
				<Loader />
			</Dimmer>
			<img alt='QR Code' width={size} height={size} src={qr} />
		</>
	)
}

export default QRCode
