import React, { useEffect, useRef, useState } from 'react'

const useInterval = (cb, delay) => {
	const savedCallback = useRef()

	const [id, setId] = useState(null)

	useEffect(() => {
		savedCallback.current = cb
	}, [cb])

	useEffect(() => {
		const tick = () => savedCallback.current()

		if (delay !== null) {
			setId(setInterval(tick, delay))
			return () => clearInterval(id)
		}
	}, [delay])

	return id
}

export default useInterval
