import './Date.less'

import React from 'react'
import { Input } from 'semantic-ui-react'
import { Property } from 'vcfer'

import { useEditMemberContext } from '../EditMember'

// TODO make this more adherent to the DATE specification
// https://tools.ietf.org/html/rfc6350#section-4.3.1

/** @type {React.FC<{ prop: Property, type: 'Y'|'M'|'D'}>} */
const DateFieldInput = ({ prop, type }) => {
	const { updateEditMember } = useEditMemberContext()

	const valCalculators = {
		Y: () => prop.value.substr(0, 4),
		M: () => prop.value.substr(4, 2),
		D: () => prop.value.substr(6, 2)
	}

	const clamp = (min, val, max) => {
		return Math.min(Math.max(val, min), max)
	}

	const handleChange = (e, { value }) => {
		let n = Number(value.replace('-', ''))
		console.log(n)
		if (isNaN(n)) n = 0
		console.log(n)
		let y = valCalculators['Y']()
		let m = valCalculators['M']()
		let d = valCalculators['D']()
		if (type === 'Y' /*&& n >= 0 && n <= 9999*/) {
			n = clamp(0, n, 9999)
			y = (n + '').padStart(4, '0')
		} else if (type === 'M' /*&& n >= 0 && n <= 12*/) {
			n = clamp(0, n, 12)
			m = (n + '').padStart(2, '0')
		} else if (type === 'D' /*&& n >= 0 && n <= 31*/) {
			n = clamp(0, n, 31)
			d = (n + '').padStart(2, '0')
		}

		prop.value = y + m + d
		updateEditMember()
	}

	return (
		<Input
			size='small'
			fluid
			value={valCalculators[type]().replace(/^0+/, '')}
			placeholder={{ Y: 'YYYY', M: 'MM', D: 'DD' }[type]}
			onChange={handleChange}
		>
			<input autoComplete='off' />
		</Input>
	)
}

const Date = ({ prop, typePicker }) => {
	return (
		<div className='prop-date'>
			<DateFieldInput prop={prop} type='Y' />
			<DateFieldInput prop={prop} type='M' />
			<DateFieldInput prop={prop} type='D' />
		</div>
	)
}

export default Date
