import React from 'react'
import { Segment, Statistic } from 'semantic-ui-react'

const StatSegment = props => {
	return (
		<Segment.Group compact horizontal {...props}>
			{props.values.map((val, i) => (
				<Segment
					padded='very'
					key={i}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<Statistic>
						<Statistic.Value>{val[0]}</Statistic.Value>
						<Statistic.Label>{val[1]}</Statistic.Label>
					</Statistic>
				</Segment>
			))}
		</Segment.Group>
	)
}

export default StatSegment
