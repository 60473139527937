import './club.less'

import React, { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Container, Header, Loader, Menu, Segment } from 'semantic-ui-react'

import ClubBanner from '../../components/ClubBanner'
import CodeBlock from '../../components/CodeBlock'
import MemberDisplay from '../../components/MemberDisplay/MemberDisplay'
import { useVisitorContext } from '../../hooks/VisitorAuthHook'
import { sortMemberLists } from '../../util'
import usePageTitle from '../../util/usePageTitle'

const ClubSplash = ({ match }) => {
	const {
		authenticated,
		useApiData,
		clubApiCall,
		clubUrl
	} = useVisitorContext()

	const [club, loading, error] = useApiData('/')

	const [filter, setFilter] = useState('')

	const processedClub = useMemo(
		() =>
			club
				? {
						...club,
						sortedLists: sortMemberLists(club.memberList, filter)
				  }
				: undefined,
		[club, filter]
	)

	usePageTitle(club && club.name ? club.name : null, [club])

	const loadMemberFromApi = async memberId => {
		const data = await clubApiCall('GET', '/members/' + memberId)
		return { ...data, id: memberId }
	}

	return (
		<div className='club-wrap'>
			<div className='club' id={match.params.memberId ? 'club-selected' : null}>
				<ClubBanner owner={club ? club.owner : null} clubId={clubUrl}>
					<Menu size='huge' secondary inverted className='banner-menu'>
						<Menu.Item className='club-title'>
							<h1>{!processedClub ? '...' : processedClub.name}</h1>
						</Menu.Item>
					</Menu>
				</ClubBanner>
				<MemberDisplay
					match={match}
					filter={filter}
					setFilter={setFilter}
					loading={!processedClub}
					sortedLists={processedClub ? processedClub.sortedLists : null}
					showIncomplete={false}
					loadMember={loadMemberFromApi}
					clubId={clubUrl}
					clubOwner={club ? club.owner : null}
				/>
				<CodeBlock loading={!processedClub}>
					{JSON.stringify(processedClub, null, 2)}
				</CodeBlock>
			</div>
		</div>
	)
}

export default ClubSplash
