import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Form } from 'semantic-ui-react'
import { Property } from 'vcfer'

import { escapeText, unescapeText } from '../../../util'
import { useEditMemberContext } from '../EditMember'

/** @type {React.FC<{ prop: Property placeholder: string }>} */
const Note = ({ prop, placeholder }) => {
	const { updateEditMember } = useEditMemberContext()

	const handleChange = (e, { value }) => {
		console.log(e.target.value)
		prop.value = escapeText(e.target.value)
		updateEditMember()
	}

	return (
		<Form>
			<TextareaAutosize
				value={unescapeText(prop.value)}
				onChange={handleChange}
				placeholder={placeholder}
			/>
		</Form>
	)
}

export default Note
