import './EditMemberEmail.less'

import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Input, Message, Popup, Segment } from 'semantic-ui-react'

import SegmentCloser from '../SegmentCloser'
import { useEditMemberContext } from './EditMember'

const EditMemberEmail = ({
	editorVersion,
	showingEmailSection,
	toggleEmailSection
}) => {
	const { member, updateEditMember } = useEditMemberContext()
	const handleEmailChange = e => {
		member.email = e.target.value.trim()
		updateEditMember()
	}

	const userMessage = (
		<Message>
			<a href='/docs/#/assoc_email'>What is an Associated Email?</a>
		</Message>
	)

	// TODO create editor message
	const editorMessage = (
		<Message>
			{/* <Message.Header>Associated Email</Message.Header> */}
			<Message>
				<a href='/docs/#/assoc_email'>What is an Associated Email?</a>
			</Message>
		</Message>
	)

	const basic = (
		<div className='editor-email-basic' onClick={() => toggleEmailSection()}>
			<div className='editor-email-basic-header'>Associated Email</div>
			<div className='editor-email-basic-email'>{member.email || 'None'}</div>
		</div>
	)

	const detailed = (
		<Segment className='editor-email-segment'>
			<SegmentCloser onClick={toggleEmailSection} />
			<div className='editor-email'>
				<span>Associated Email</span>
				<Input
					className='editor-email-input'
					placeholder='Add an Associated Email'
					fluid
					icon='envelope outline'
					iconPosition='left'
					defaultValue={member.email}
					onChange={handleEmailChange}
				/>
			</div>
			{editorVersion ? editorMessage : userMessage}
		</Segment>
	)

	return (
		<div className='editor-email-wrap'>
			{showingEmailSection ? detailed : basic}
		</div>
	)
}
export default EditMemberEmail
