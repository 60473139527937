import React, { useEffect, useState } from 'react'

const useKeyPress = targetKey => {
	const [keyPressed, setKeyPressed] = useState(false)

	const downHandler = ({ key }) => {
		if (targetKey === key) {
			setKeyPressed(true)
		}
	}

	const upHandler = ({ key }) => {
		if (targetKey === key) {
			setKeyPressed(false)
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', downHandler)
		window.addEventListener('keyup', upHandler)

		return () => {
			window.removeEventListener('keydown', downHandler)
			window.removeEventListener('keyup', upHandler)
		}
	}, [])

	return keyPressed
}

export default useKeyPress
