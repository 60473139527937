import 'react-semantic-toasts/styles/react-semantic-alert.css'

import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import PrivateRoute from './components/PrivateRoute'
import { AuthProvider } from './hooks/AuthHook'
import App from './pages/App'
import ClubRouter from './pages/club/ClubRouter'
import Login from './pages/Login'
import NotFound404 from './pages/NotFound404'
import About from './pages/other/About'
import Storefront from './pages/Storefront'
import ThemingLayout from './pages/ThemingLayout'

const Main = () => {
	return (
		<AuthProvider>
			<SemanticToastContainer position='top-center' />
			<BrowserRouter>
				<Switch>
					<Route path='/c/:clubUrl' component={ClubRouter} />
					<PrivateRoute path={['/app']} component={App} />
					<Route exact path='/login' component={Login} />
					<Route
						exact
						path='/register'
						render={props => <Login register {...props} />}
					/>
					<Route
						path='/docs'
						component={() => {
							window.location.reload()
						}}
					/>
					<Route exact path='/theming' component={ThemingLayout} />
					<Route path='/' component={Storefront} />
					<Route component={NotFound404} />
				</Switch>
			</BrowserRouter>
		</AuthProvider>
	)
}

export default Main
