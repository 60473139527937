import React, { useEffect, useState } from 'react'

/**
 * @returns {number}
 */
const useWindowWidth = () => {
	const [width, setWidth] = useState(window.innerWidth)
	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth)

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	})

	return width
}

export default useWindowWidth
