import './ViewMemberPrint.less'

import React from 'react'
import { Header } from 'semantic-ui-react'
import VCard from 'vcfer'

import vutil from '../../util/vutil'
import MemberImage from '../MemberImage'
import { ViewMemberContext } from '../ViewMember2/ViewMember'
import ViewPropHandler from '../ViewMember2/ViewPropHandler'

/**
 * @param {object} props
 * @param {MemberDocument | MemberDocumentWithId} props.member
 * @param {string} props.clubId used to display member images
 * @param {500|250|50|25} props.thumbSize
 * @param {number} props.size
 */
const ViewMemberPrint = ({
	member,
	clubId,
	memberId,
	thumbSize = 25,
	size = 25
}) => {
	const card = new VCard(JSON.parse(member.card))

	const propHandlers = (
		<>
			<ViewPropHandler prop='nickname' name='Nickname' showType={false} />
			<ViewPropHandler prop='gender' name='Gender' showType={false} />
			<ViewPropHandler prop='tel' name='Phone' />
			<ViewPropHandler prop='email' name='Email' />
			<ViewPropHandler prop='adr' name='Address' />
			<ViewPropHandler
				prop='xSocialProfile'
				name='Social Profile'
				component='generic'
			/>
			<ViewPropHandler prop='impp' name='Messenger' component='generic' />
			<ViewPropHandler
				prop='bday'
				name='Birthday'
				showType={false}
				component='date'
			/>
			<ViewPropHandler
				prop='anniversary'
				name='Anniversary'
				showType={false}
				component='date'
			/>
			<ViewPropHandler prop='note' name='Note' showType={false} />
		</>
	)

	if (!vutil(card).isValid()) return null
	return (
		<div className='print-member'>
			<div className='print-member-header'>
				<MemberImage
					owner={member.owner}
					clubId={clubId}
					memberId={member.id || memberId}
					thumbSize={thumbSize}
					size={size}
					hideOnError
				/>
				<Header>{vutil(card).title()}</Header>
			</div>
			<ViewMemberContext.Provider value={{ card }}>
				<div className='prop-handlers'>{propHandlers}</div>
			</ViewMemberContext.Provider>
		</div>
	)
}

export default ViewMemberPrint
