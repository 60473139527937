import React, { useState } from 'react'
import { Menu, Popup } from 'semantic-ui-react'

import { defaultTypes } from '../../util'
import AddButton from './AddButton'
import { useEditMemberContext } from './EditMember'

const AddOtherMenu = () => {
	const {
		card,
		updateEditMember,
		otherMenu,
		showBlankProp,
		showingBlankProp
	} = useEditMemberContext()

	const handleClick = name => {
		if (otherMenu[name].isSemicolonSubset) {
			showBlankProp(otherMenu[name].prop, otherMenu[name].semicolonIndex)
		} else {
			card.add(otherMenu[name].defaultProp())
		}
		updateEditMember()
		setShowing(false)
	}

	const [showing, setShowing] = useState(false)

	const list = Object.values(otherMenu).map(
		({
			prop,
			name,
			defaultProp,
			singleton,
			semicolonIndex,
			isSemicolonSubset
		}) => {
			if (showingBlankProp(prop, semicolonIndex)) return null
			if (singleton && !isSemicolonSubset && card.has(prop)) return null

			return (
				<Menu.Item key={name} onClick={() => handleClick(name)}>
					{name}
				</Menu.Item>
			)
		}
	)

	return (
		<Popup
			pinned
			on='click'
			position='top center'
			trigger={<AddButton icon='plus' content='Add Other Field' />}
			open={showing}
			onOpen={() => setShowing(true)}
			onClose={() => setShowing(false)}
			as={Menu}
			vertical
			className='add-other-menu'
		>
			{list}
		</Popup>
	)
}

export default AddOtherMenu
