import 'firebase/firestore'

import Chance from 'chance'
import * as firebase from 'firebase/app'
import React, { useEffect, useReducer, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Link } from 'react-router-dom'
import { Button, Loader, Popup } from 'semantic-ui-react'

import { useAuthContext } from '../hooks/AuthHook'
import { randomCard, randomCompany, randomVCard } from '../util'
import CodeBlock from './CodeBlock'
import QRCode from './QRCode'

const chance = new Chance()

const AddMemberButton = ({ children, club, cardFunction, emailFunction }) => {
	const [addingMember, setAddingMember] = useState(false)
	const [success, setSuccess] = useState(false)

	const addMember = async () => {
		setAddingMember(true)
		const res = await firebase.functions().httpsCallable('createMember')({
			club,
			email: emailFunction ? emailFunction() : undefined,
			card: cardFunction ? JSON.stringify(cardFunction()) : undefined
		})
		setAddingMember(false)
		setSuccess(res.data.success)
		if (!res.data.success) {
			console.log(res.data.error)
		}
	}

	return (
		<Button
			loading={addingMember}
			disabled={addingMember}
			onClick={addMember}
			positive={success}
		>
			{children}
		</Button>
	)
}

const DebugMemberList = ({ club }) => {
	const { user } = useAuthContext()

	const memberCollection = firebase
		.firestore()
		.collection('clubs')
		.doc(club)
		.collection('members')

	const [members, loading, error] = useCollectionData(
		memberCollection.where('owner', '==', user.uid),
		{ idField: 'id' }
	)

	return (
		<>
			<AddMemberButton club={club}>Add Blank Member</AddMemberButton>
			<AddMemberButton club={club} emailFunction={() => chance.email()}>
				Add Incomplete Member
			</AddMemberButton>
			<AddMemberButton
				club={club}
				cardFunction={() => randomVCard().toJCard()}
				emailFunction={() => chance.email()}
			>
				Add Random Member
			</AddMemberButton>
			<AddMemberButton
				club={club}
				cardFunction={randomCompany}
				emailFunction={() => chance.email()}
			>
				Add Random Company
			</AddMemberButton>
			<br />

			<CodeBlock loading={loading}>
				{JSON.stringify(members, null, 2)}
			</CodeBlock>
		</>
	)
}

export default DebugMemberList
