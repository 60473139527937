import 'firebase/functions'

import './EditMember.less'

import { isArray } from 'util'

import * as firebase from 'firebase/app'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import VCard, { Property } from 'vcfer'

import CodeBlock from '../CodeBlock'
import ErrorBoundary from '../ErrorBoundary'
import AddOtherMenu from './AddOtherMenu'
import Address from './CustomInputComponents/Address'
import Date from './CustomInputComponents/Date'
import Note from './CustomInputComponents/Note'
import EditMemberEmail from './EditMemberEmail'
import EditMemberMenu from './EditMemberMenu'
import EditMemberPicture from './EditMemberPicture'
import PropHandler from './PropHandler'

const EditMemberContext = React.createContext({
	member: null,
	clubId: '',
	card: new VCard(),
	inHeader: false,
	updateEditMember: () => {},
	blankPropsShown: {},
	showingBlankProp: (prop, semicolonIndex) => {},
	showBlankProp: (prop, semicolonIndex) => {},
	otherMenu: {},
	addToOtherMenu: (prop, name) => {},
	uploadMemberImage: () => {},
	deleteMemberImage: () => {},
	requiredErrors: {},
	hasRequiredError: () => {},
	canSave: () => {}
})

export const useEditMemberContext = () => React.useContext(EditMemberContext)

/** @type {React.FC<{
  clubId: string,
  member: any,
  handleSubmit: () => void
}>} */
const EditMember = ({
	clubId,
	member,
	handleSubmit,
	uploadMemberImage,
	deleteMemberImage,
	editorVersion = false
}) => {
	const history = useHistory()

	const [card, setCard] = useState(new VCard(JSON.parse(member.card)))

	const [blankPropsShown, setBlankPropsShown] = useState({})

	const showingBlankProp = (prop, semicolonIndex) => {
		if (!blankPropsShown[semicolonIndex]) return false
		return blankPropsShown[semicolonIndex].includes(prop)
	}

	const showBlankProp = (prop, semicolonIndex) => {
		if (!showingBlankProp(prop, semicolonIndex)) {
			setBlankPropsShown(b => {
				if (!b[semicolonIndex]) b[semicolonIndex] = []
				b[semicolonIndex].push(prop)
				return b
			})
		}
	}

	const updateEditMember = () => {
		const cloneCard = new VCard(card.toJCard())
		setCard(cloneCard)
	}

	const handleSaveButton = async e => {
		card.parseFullName({
			set: true
		})
		await handleSubmit(
			{
				...member,
				card: JSON.stringify(card.toJCard())
			},
			setSubmitStatus
		)
	}

	const deleteMember = async () => {
		// TODO
		await firebase.functions().httpsCallable('deleteMember')({
			id: member.id,
			club: clubId
		})
		history.push('/app/club/' + clubId)
	}

	const [error, setError] = useState(null)

	const [submitStatus, setSubmitStatus] = useState(false)

	const [otherMenu, setOtherMenu] = useState({})

	/**
	 * Validate the card.
	 */
	const hasRequiredError = useCallback(() => {
		if (!card.getOne('n')) return true

		const n = card.getOne('n').value.split(';')
		const org = card.has('org') ? card.getOne('org').value.split(';') : []

		return !n[0] && !n[1] && !org[0]
	}, [card])

	const canSave = useCallback(() => {
		return !hasRequiredError() || member.email
	}, [card, member, hasRequiredError])

	const addToOtherMenu = ({
		prop,
		name,
		defaultProp,
		singleton,
		semicolonIndex,
		isSemicolonSubset
	}) => {
		setOtherMenu(o => {
			o[name] = {
				prop,
				name,
				defaultProp,
				singleton,
				semicolonIndex,
				isSemicolonSubset
			}
			return o
		})
	}

	const [showingEmailSection, setShowingEmailSection] = useState(false)

	const toggleEmailSection = () => setShowingEmailSection(e => !e)

	/** There are multiple providers in the JSX tree. */
	const providerDefault = {
		member,
		clubId,
		card,
		inHeader: false,
		updateEditMember,
		blankPropsShown,
		showingBlankProp,
		showBlankProp,
		otherMenu,
		addToOtherMenu,
		uploadMemberImage,
		deleteMemberImage,
		hasRequiredError,
		canSave
	}
	if (!error) {
		return (
			<div className='edit-member'>
				<ErrorBoundary onError={(err, info) => setError(info)}>
					<EditMemberContext.Provider value={providerDefault}>
						<EditMemberMenu
							editLink={`/c/${clubId}/e/${member.editUrl}`}
							deleteMember={deleteMember}
							handleSaveButton={handleSaveButton}
							editorVersion={editorVersion}
						/>
						<EditMemberEmail
							editorVersion={editorVersion}
							showingEmailSection={showingEmailSection}
							toggleEmailSection={toggleEmailSection}
						/>
					</EditMemberContext.Provider>
					<div className='edit-member-header'>
						<EditMemberContext.Provider
							value={{
								...providerDefault,
								inHeader: true
							}}
						>
							<div className='edit-member-picture'>
								<EditMemberPicture
									owner={member.owner}
									clubId={clubId}
									memberId={member.id}
								/>
							</div>
							<div className='edit-member-header-fields'>
								<div className='header-name prop-input-group'>
									<PropHandler
										prop='n'
										name='Prefix'
										semicolonIndex={3}
										singleton
										isSemicolonSubset
										other
									/>
									{/* ============================== */}
									<PropHandler
										prop='n'
										name='First'
										semicolonIndex={1}
										required
										singleton
									/>
									<PropHandler
										prop='nickname'
										name='Nickname'
										singleton
										other
									/>
									<PropHandler
										prop='n'
										name='Middle'
										semicolonIndex={2}
										alwaysVisible
										singleton
									/>
									<PropHandler
										prop='n'
										name='Last'
										semicolonIndex={0}
										required
										singleton
									/>
									{/* ============================== */}
									<PropHandler
										prop='n'
										name='Suffix'
										semicolonIndex={4}
										singleton
										isSemicolonSubset
										other
									/>
								</div>
								<div className='prop-input-group'>
									<PropHandler
										prop='org'
										name='Organization'
										semicolonIndex={0}
										required
										singleton
									/>
									<PropHandler
										prop='org'
										name='Department'
										semicolonIndex={1}
										singleton
										isSemicolonSubset
										other
									/>
									<PropHandler prop='title' name='Title' singleton other />
								</div>
							</div>
						</EditMemberContext.Provider>
					</div>
					<div className='edit-member-body'>
						<EditMemberContext.Provider
							value={{
								...providerDefault,
								inHeader: false
							}}
						>
							<PropHandler
								prop='gender'
								name='Gender'
								singleton
								other
								pickerTypes={[
									['M', 'Male'],
									['F', 'Female'],
									['O', 'Other'],
									['N', 'None'],
									['U', 'Unknown']
								]}
								pickerModifiesSemicolonIndex={0}
								semicolonIndex={1}
								defaultProp={() =>
									new Property('gender', 'U;', { value: 'text' })
								}
							/>
							<PropHandler
								prop='tel'
								name='Phone'
								addButton
								pickerTypes={[
									['work', 'Work'],
									['home', 'Home'],
									['mobile', 'Mobile'],
									['fax', 'Fax']
								]}
								pickerTypesExclude={['voice']}
								defaultProp={() => new Property('tel', '', { type: 'work' })}
							/>
							<PropHandler
								prop='email'
								name='Email'
								addButton
								pickerTypes={[
									['work', 'Work'],
									['personal', 'Personal']
								]}
								pickerTypesExclude={['internet']}
								defaultProp={() =>
									new Property('email', '', { type: 'personal' })
								}
							/>
							<PropHandler
								prop='adr'
								name='Address'
								addButton
								component={Address}
								pickerTypes={[
									['work', 'Work'],
									['home', 'Home']
								]}
								defaultProp={() =>
									new Property('adr', ';;;;;;', { value: 'text', type: 'work' })
								}
							/>
							<PropHandler
								prop='url'
								name='URL'
								other
								pickerTypes={[
									['work', 'Work'],
									['personal', 'Personal']
								]}
								defaultProp={() =>
									new Property('url', 'http://', { value: 'uri' })
								}
							/>
							<PropHandler
								prop='xSocialprofile'
								name='Social Profile'
								addButton
								pickerTypes={[
									['facebook', 'Facebook'],
									['twitter', 'Twitter'],
									['linkedin', 'Linkedin'],
									['whatsapp', 'Whatsapp'],
									['groupme', 'GroupMe'],
									['discord', 'Discord'],
									['flickr', 'Flickr']
								]}
								defaultProp={() =>
									new Property('xSocialprofile', '', { type: 'twitter' })
								}
							/>
							<PropHandler
								prop='bday'
								name='Birthday'
								other
								component={Date}
								defaultProp={() => new Property('bday', '20200101')}
							/>
							<PropHandler
								prop='anniversary'
								name='Anniversary'
								other
								component={Date}
								defaultProp={() => new Property('anniversary', '20200101')}
							/>
							<PropHandler
								prop='note'
								name='Note'
								other
								singleton
								component={Note}
								defaultProp={() => new Property('note', '', { type: 'text' })}
							/>
							<AddOtherMenu />
						</EditMemberContext.Provider>
					</div>
				</ErrorBoundary>
				{submitStatus === 'submitting' && (
					<div className='saving-dimmer-wrap'>
						<div className='saving-dimmer'>
							<Loader active content='Saving...' size='massive' />
						</div>
					</div>
				)}
				<CodeBlock>{card.toString()}</CodeBlock>
			</div>
		)
	} else {
		return (
			<div className='edit-member'>
				<h1>Error:</h1>
				<CodeBlock alwaysShow>{JSON.stringify(error, null, 2)}</CodeBlock>
			</div>
		)
	}
}

export default EditMember
