import './CodeBlock.less'

import React, { useEffect, useState } from 'react'
import { Segment } from 'semantic-ui-react'

import useKeyPress from '../util/useKeyPress'

const useToggle = () => {
	const backslashPressed = useKeyPress('\\')

	const [toggled, setToggled] = useState(false)

	useEffect(() => {
		if (backslashPressed) setToggled(!toggled)
	}, [backslashPressed])

	return toggled
}

const CodeBlock = ({ children, loading, alwaysShow }) => {
	const toggled = useToggle()

	if (process.env.NODE_ENV === 'production') return null

	return toggled || alwaysShow ? (
		<Segment loading={loading} className='code-block'>
			<pre>
				<code>{children}</code>
			</pre>
		</Segment>
	) : null
}

export default CodeBlock

export const JSONCodeBlock = ({ json, loading }) => {
	const toggled = useToggle()

	return toggled ? (
		<Segment loading={loading} className='code-block'>
			<pre>
				<code>{JSON.stringify(json, null, 2)}</code>
			</pre>
		</Segment>
	) : null
}
