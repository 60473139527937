import './Home.less'

import React from 'react'
import { Divider } from 'semantic-ui-react'

import LoginPanel from '../../components/LoginPanel'
import { useAuthContext } from '../../hooks/AuthHook'

const Home = () => {
	const { user } = useAuthContext()

	return (
		<div id='home'>
			<div className='centerizer left billboard'>
				<h1>Say goodbye to manual entry.</h1>
				<p>
					Switch to Circlus to manage your team's contact info &#8212;{' '}
					<i>without having to enter it yourself.</i>
				</p>
			</div>
			{!user && (
				<>
					<div style={{ width: 0, height: 20 }} />
					<div id='signup-box'>
						<h1 style={{ margin: 0, textAlign: 'center' }}>Sign Up</h1>
						<Divider />
						<LoginPanel register />
					</div>
				</>
			)}
		</div>
	)
}

export default Home
