import 'firebase/auth'

import './ForgotPassword.less'

import * as firebase from 'firebase/app'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Form, Input, Message } from 'semantic-ui-react'

const ForgotPassword = ({ match, ...props }) => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)

	const [email, setEmail] = useState('')

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')

	const handleSubmit = async () => {
		setLoading(true)
		try {
			await firebase.auth().sendPasswordResetEmail(email)
			setError('')
		} catch (err) {
			setError(err.message)
		}
		setLoading(false)
	}

	// if (query.get('code'))
	// 	return <ForgotPasswordChange code={query.get('code')} />

	return (
		<div className='flex-center'>
			<div className='flex-center' style={{ maxWidth: 400 }}>
				<p style={{ textAlign: 'center' }}>
					Enter your Email address below. We'll send you an Email containing a
					link you can use to reset your password.
				</p>
				<Form
					onSubmit={handleSubmit}
					id='forgot-password-form'
					loading={loading}
					error={error}
				>
					<Message error content={error} />
					<Form.Input
						placeholder='Email'
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>

					<Button type='submit'>Send Reset Password Email</Button>
				</Form>
			</div>
		</div>
	)
}

const ForgotPasswordChange = ({ code }) => {
	return <>{code}</>
}

export default ForgotPassword
