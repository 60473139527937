import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
	}
	componentDidCatch(error, info) {
		console.error(error)
		this.props.onError(error, info)
	}

	static getDerivedStateFromError() {}

	render() {
		return this.props.children
	}
}

export default ErrorBoundary
