import 'firebase/auth'

import * as firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'

/** @type {React.Context<IAuthContext>} */
export const AuthContext = React.createContext()
export const useAuthContext = () => React.useContext(AuthContext)
export const AuthProvider = ({ children }) => {
	const [loadingUser, setLoadingUser] = useState(true)
	const [user, setUser] = useState(firebase.auth().currentUser)

	useEffect(_ => {
		const unsub = firebase.auth().onAuthStateChanged(user => {
			setUser(user)
			if (loadingUser) setLoadingUser(false)
			ReactGA.set({ userId: user.uid })
		})

		return () => unsub()
	}, [])

	const signInGoogle = () =>
		firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())

	const registerEmail = async (email, password) => {
		const res = await firebase
			.auth()
			.createUserWithEmailAndPassword(email, password)

		res.user.sendEmailVerification()
	}

	const signInEmail = (email, password) =>
		firebase.auth().signInWithEmailAndPassword(email, password)

	const signOut = () =>
		firebase
			.auth()
			.signOut()
			.then(_ => {
				localStorage.removeItem('last_club_id')
				console.log('succesfully logged out')
				ReactGA.set({ userId: null })
			})

	return (
		<AuthContext.Provider
			value={{
				user,
				loadingUser,
				signInGoogle,
				registerEmail,
				signInEmail,
				signOut
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
