import './Pricing.less'

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'semantic-ui-react'

const Pricing = () => {
	return (
		<div className='pricing-wrap'>
			<PricingPlan
				name='Beta Plan'
				subtitle='Early adopters can sign up at this reduced rate.'
				monthlyPrice={'$10.00'}
				freeTrial='1 month'
			/>
		</div>
	)
}

const PricingPlan = ({ name, subtitle, monthlyPrice, freeTrial }) => {
	return (
		<div className='pricing-plan'>
			<div className='pricing-plan-name'>
				<div>{name}</div>
				<div className='pricing-plan-subtitle'>{subtitle}</div>
			</div>
			<Link to='/register'>
				<div className='pricing-plan-lift'>
					<Card className='pricing-plan-card'>
						{!!freeTrial && (
							<Card.Content className='pricing-plan-free-trial'>
								<header>Free Trial</header>
								<div>{freeTrial}</div>
							</Card.Content>
						)}
						<Card.Content className='pricing-plan-price'>
							<span>{monthlyPrice}</span>
							<div>/ per month / per club</div>
						</Card.Content>
					</Card>
					<div className='pricing-plan-signup'>Get This Plan</div>
				</div>
			</Link>
		</div>
	)
}

export default Pricing
