import 'firebase/firestore'

import './ClubSettings.less'

import * as firebase from 'firebase/app'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
	Button,
	Checkbox,
	Container,
	Dimmer,
	Form,
	Grid,
	Header,
	Icon,
	Input,
	Loader,
	Menu,
	Message,
	Segment
} from 'semantic-ui-react'

import CodeBlock from '../../../components/CodeBlock'
import SettingsMenu from '../../../components/SettingsMenu'
import usePageTitle from '../../../util/usePageTitle'

const SettingsError = ({ disabled = true, subtitle, errorMessage }) => {
	return disabled ? null : (
		<Message error>
			<Message.Header>Error!</Message.Header>
			<p>
				{subtitle}
				<br /> (Error: <code>{errorMessage}</code>)
			</p>
		</Message>
	)
}

/**
 *
 * @param {object} props
 * @param {firebase.firestore.DocumentReference} props.clubReference
 * @param {ClubDocument} props.clubData
 * @param {string} props.clubId
 */
const ClubSettings = ({ clubReference, clubData, clubId }) => {
	const [active, setActive] = useState('Club Info')

	console.log(clubData)

	const settingsPages = {
		'Club Info': ClubInfoSettings,
		Password: PasswordSettings,
		Payment: PaymentSettings,
		'Delete Club': DeleteSettings
	}

	const ActiveSettingsPage = useMemo(() => {
		return settingsPages[active]
	}, [active])

	const header = (
		<>
			<Link to={'/app/club/' + clubId} style={{ color: 'black' }}>
				<Icon name='arrow left' />
			</Link>
			<span>Settings - {clubData.name}</span>
		</>
	)

	return (
		<SettingsMenu
			header={header}
			pages={settingsPages}
			defaultPage='Club Info'
			data={{
				clubReference,
				clubData,
				clubId
			}}
		/>
	)
}

const ClubInfoSettings = ({ clubReference, clubData, clubId }) => {
	const [formState, setFormState] = useState({
		name: clubData.name
	})

	const [submitting, setSubmitting] = useState(false)

	const [error, setError] = useState(null)

	const handleSubmit = e => {
		setSubmitting(true)
		firebase
			.functions()
			.httpsCallable('changeClubName')({
				club: clubId,
				name: formState.name
			})
			.then(res => {
				setSubmitting(false)
			})
			.catch(err => {
				setSubmitting(false)
				setError(err.message)
			})
	}

	return (
		<>
			<SettingsError
				disabled={!error}
				subtitle='There was an error while attempting to update your settings.'
				errorMessage={error}
			/>
			<Form onSubmit={handleSubmit}>
				<Form.Input label='Club Name' disabled={submitting}>
					<input
						value={formState.name}
						onChange={e => setFormState({ ...formState, name: e.target.value })}
					/>
				</Form.Input>

				<Button type='submit' loading={submitting}>
					Save Changes
				</Button>
			</Form>
		</>
	)
}

const PasswordSettings = ({ clubReference, clubData, clubId }) => {
	const [formState, setFormState] = useState({
		pass1: '',
		pass2: '',
		error: null,
		submitting: false,
		success: false,
		passwordEnabled: clubData.passwordEnabled
	})

	const { passwordSet } = clubData

	const enableFields =
		(formState.passwordEnabled && passwordSet) || !passwordSet

	const handleSubmit = () => {
		setFormState({
			...formState,
			submitting: true,
			error: null,
			success: false
		})
		firebase
			.functions()
			.httpsCallable('changeClubPassword')({
				club: clubId,
				pass1: formState.pass1,
				pass2: formState.pass2
			})
			.then(res => {
				// successful change
				setFormState({
					...formState,
					pass1: '',
					pass2: '',
					submitting: false,
					success: true,
					error: null
				})
			})
			.catch(err => {
				// change failed
				setFormState({
					...formState,
					submitting: false,
					error: err.message
				})
			})
	}

	const [submittingPasswordEnabled, setSubmittingPasswordEnabled] = useState(
		false
	)

	const handleChangePasswordEnabled = () => {
		setSubmittingPasswordEnabled(true)
		firebase
			.firestore()
			.collection('clubs')
			.doc(clubId)
			.update({
				passwordEnabled: !formState.passwordEnabled // why does this have to be inverted...? literally backwards setting
			})
			.then(res => setSubmittingPasswordEnabled(false))
			.catch(err => {
				setSubmittingPasswordEnabled(false)
				setFormState({ ...formState, error: err.message })
			})
	}

	const desc = passwordSet ? 'Change Password' : 'Create Password'

	return (
		<>
			<SettingsError
				disabled={!formState.error}
				subtitle='There was an error while setting your password. Please make sure both password fields match.'
				errorMessage={formState.error}
			/>
			{formState.success ? (
				<Message success icon>
					<Icon name='check' />
					<Message.Header>Password Changed!</Message.Header>
				</Message>
			) : null}
			{passwordSet ? (
				<>
					<Checkbox
						label={
							<label>
								Enable Password
								<Loader
									inline
									active={submittingPasswordEnabled}
									size='tiny'
									style={{ marginLeft: '10px' }}
								/>
							</label>
						}
						checked={formState.passwordEnabled}
						toggle
						disabled={submittingPasswordEnabled}
						onChange={() => {
							setFormState({
								...formState,
								passwordEnabled: !formState.passwordEnabled
							})
							handleChangePasswordEnabled()
						}}
					/>
				</>
			) : (
				<Message info>
					<Message.Header>Create a password for your club</Message.Header>
					<p>
						Protect your club&apos;s data with a password only your members
						know.
						<br />
						You&apos;ll have to tell it to anybody who wants to view your
						club&apos;s data.
					</p>
					<p>
						(You&apos;ll be able to change the password, and turn it on and off,
						after creating the first one.)
					</p>
				</Message>
			)}
			<Form onSubmit={handleSubmit}>
				<Form.Input
					type='password'
					name='pass1'
					placeholder='New Password'
					disabled={!enableFields}
					label={<span>{desc}</span>}
					onChange={e => {
						setFormState({
							...formState,
							pass1: e.target.value,
							error: null,
							success: false
						})
					}}
				/>

				<Form.Input
					type='password'
					name='pass2'
					placeholder='Confirm New Password'
					disabled={!enableFields}
					onChange={e => {
						setFormState({
							...formState,
							pass2: e.target.value,
							error: null,
							success: false
						})
					}}
				/>
				<Button type='submit' loading={formState.submitting}>
					{desc}
				</Button>
			</Form>
		</>
	)
}

const PaymentSettings = ({ clubReference, clubData, clubId }) => {
	const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

	const history = useHistory()

	const [loadingOne, setLoadingOne] = useState(false)

	const [subscription, setSubscription] = useState(null)

	const [loadingSub, setLoadingSub] = useState(true)

	useEffect(() => {
		;(async () => {
			setLoadingSub(true)
			try {
				const res = await firebase
					.functions()
					.httpsCallable('getClubSubscription')({
					clubId
				})
				setSubscription(res.data)
			} catch (err) {
				console.error(err)
			}
			setLoadingSub(false)
		})()
	}, [])

	const redirectToStripe = async () => {
		setLoadingOne(true)
		const res = await firebase.functions().httpsCallable('updateClubPayment')({
			clubId
		})
		await stripe.redirectToCheckout({ sessionId: res.data.sessionId })
		setLoadingOne(false)
	}

	return (
		<>
			<Dimmer.Dimmable dimmed={loadingSub}>
				<Dimmer active={loadingSub} inverted>
					<Loader>Loading Subscription Data...</Loader>
				</Dimmer>

				<div className='pay-button'>
					<Button
						as={'a'}
						target='_blank'
						href={
							subscription
								? subscription.latest_invoice.hosted_invoice_url
								: null
						}
					>
						Open Latest Invoice
					</Button>
					{subscription &&
						(subscription.latest_invoice.paid ? (
							<p>You've already paid this invoice.</p>
						) : (
							<p>
								It looks like your latest invoice hasn't been paid yet. Click
								the button to pay it manually.
							</p>
						))}
					<Button onClick={redirectToStripe} loading={loadingOne}>
						Update Club Payment Information
					</Button>
				</div>
				<CodeBlock>{JSON.stringify(subscription, null, 2)}</CodeBlock>
			</Dimmer.Dimmable>
		</>
	)
}

const DeleteSettings = ({ clubReference, clubData, clubId }) => {
	const [sentEmail, setSentEmail] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleClick = async e => {
		setLoading(true)
		try {
			const res = await firebase
				.functions()
				.httpsCallable('requestDeleteEmail')({
				club: clubId
			})
			if (res) setSentEmail(true)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	return (
		<>
			{sentEmail ? (
				<Message>
					<Message.Header>Deletion Confirmation Email Sent</Message.Header>
					<p>
						Check your email to confirm the <b>permanent</b> deletion of this
						club.
					</p>
				</Message>
			) : null}
			<Button loading={loading} onClick={handleClick} negative>
				Delete Club
			</Button>
			<p>Deleting a club is permanent and cannot be undone.</p>
			<p>
				When you click &apos;Delete Club,&apos; an email will be sent to you
				requiring your confirmation.
			</p>
		</>
	)
}

export default ClubSettings
