import React from 'react'
import { Label } from 'semantic-ui-react'

import { useMemberDisplayContext } from './MemberDisplay'

const IncompleteEntriesList = ({ incompletes }) => {
	const { onSelectMember, selectedMember } = useMemberDisplayContext()

	return (
		<div className='label-entry-list'>
			{incompletes.map((inc, i) => {
				const isSelected = selectedMember === inc.id

				return (
					<Label
						circular
						basic
						key={i}
						onClick={() => onSelectMember(inc.id)}
						className={'entry-label ' + (isSelected ? 'selected' : null)}
						size='large'
					>
						{inc.email}
					</Label>
				)
			})}
		</div>
	)
}

export default IncompleteEntriesList
