import 'firebase/firestore'
import 'firebase/functions'

import './UserClub.less'

import * as firebase from 'firebase/app'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState
} from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-semantic-toasts'
import {
	Button,
	Dimmer,
	Dropdown,
	Header,
	Icon,
	Loader,
	Menu,
	Message,
	Modal,
	Popup,
	Segment
} from 'semantic-ui-react'

import ClubBanner from '../../../components/ClubBanner'
import ClubLoadingSkeleton from '../../../components/ClubLoadingSkeleton'
import CodeBlock from '../../../components/CodeBlock'
import DebugMemberList from '../../../components/DebugMemberList'
import MemberDisplay from '../../../components/MemberDisplay/MemberDisplay'
import OverflowButtonGroup from '../../../components/OverflowButtonGroup'
import QRCode from '../../../components/QRCode'
import StatSegment from '../../../components/StatSegment'
import VcfUploadOverlay from '../../../components/VcfUploadOverlay'
import { useAppContext } from '../../../hooks/AppHook'
import useWindowWidth from '../../../hooks/useWindowWidth'
import { fileToBase64, sortMemberLists } from '../../../util'
import usePageTitle from '../../../util/usePageTitle'
import NotFound404 from '../../NotFound404'
import ClubSettings from './ClubSettings'
import ConfirmDelete from './ConfirmDelete'
import LinkMenu from './LinkMenu'
import NoClubFound from './NoClubFound'
import PrintVersion from './PrintVersion'
import SendMassEmail from './SendMassEmail'

const UserClub = ({ match }) => {
	/**
	[x] filter,
	[x] setFilter,
	[x] clubData,
	[x] loadMember,
	[x] updateMember,
	[x] uploadMemberImage,
	[x] deleteMemberImage,
	[x] addVcfMembers,
	[x] clubId
	 */

	// remember last club visited.
	const location = useLocation()
	useEffect(() => {
		if (match.params.clubUrl)
			localStorage.setItem('last_club_id', match.params.clubUrl)
	}, [location])

	const clubId = match.params.clubUrl

	const clubReference = firebase
		.firestore()
		.collection('clubs')
		.doc(match.params.clubUrl)

	const [club, loadingClub, errorClub] = useDocumentData(clubReference)

	// it's unlikely that router changes actually reload the entire club.
	// because loadingClub doesn't change. Club does, though.
	// this could just be the firestore listener working.

	const [filter, setFilter] = useState('') // TODO where is this defined?

	/** @type {SortedClubDocument} */
	const clubData = useMemo(() => {
		if (!club) return undefined
		return {
			...club,
			sortedLists: sortMemberLists(club.memberList, filter)
		}
	}, [club, filter])

	const memberCount = useCallback(() => {
		if (clubData.sortedLists.filtered)
			return clubData.sortedLists.complete.length

		return Object.values(clubData.sortedLists.complete).reduce((acc, val) => {
			return acc + val[1].length
		}, 0)
	}, [clubData])

	usePageTitle(club && club.name ? club.name : null, [club])

	const history = useHistory()

	const loadMember = async memberId => {
		try {
			const doc = await clubReference
				.collection('members')
				.doc(memberId)
				.get()

			if (!doc.exists) return null
			return {
				...doc.data(),
				id: doc.id
			}
		} catch (err) {
			console.error('missing or invalid member', memberId)
			console.error(err)
			history.push('/app/club/' + clubId)
			return null
		}
	}

	/**
	 *
	 * @param {MemberDocumentWithId} member
	 * @param {(submitStatus: string) => void} setSubmitStatus
	 */
	const updateMember = async (member, setSubmitStatus) => {
		setSubmitStatus('submitting')
		try {
			await firebase.functions().httpsCallable('updateMember')({
				club: clubReference.id,
				id: member.id,
				card: member.card,
				email: member.email
			})
			setSubmitStatus('submitted')
		} catch (err) {
			console.error(err)
			toast({
				color: 'red',
				icon: 'warning circle',
				title: 'Wuh Oh',
				description: `There was an error while saving a contact: ${err}`
			})
			setSubmitStatus('error')
		}
	}

	/**
	 *
	 * @param {File} file
	 * @param {string} owner
	 * @param {string} clubId
	 * @param {string} memberId
	 */
	const uploadMemberImage = async (file, owner, clubId, memberId) => {
		// upload member image
		const fileData = await new Promise((resolve, reject) => {
			// convert to base64 string
			const reader = new FileReader()
			reader.onload = e => resolve(e.target.result)
			reader.readAsDataURL(file)
		})

		console.log(fileData)

		const res = await firebase.functions().httpsCallable('uploadMemberImage')({
			club: clubId,
			member: memberId,
			imageBase64: fileData
		})
		return res.data
	}

	const deleteMemberImage = async (clubId, memberId) => {
		const res = await firebase.functions().httpsCallable('deleteMemberImage')({
			club: clubId,
			member: memberId
		})
		return res.data
	}

	/**
	 *
	 * @param {VCard[]} members
	 */
	const addVcfMembers = async members => {
		const clubUrl = match.params.clubUrl
		const allRes = await Promise.all(
			members.map(async member => {
				const res = await firebase.functions().httpsCallable('createMember')({
					club: clubUrl,
					email: undefined,
					card: JSON.stringify(member.toJCard())
				})
				return res.data
			})
		).catch(err => console.error('Add VCF members error', err))
		return allRes
	}

	const payWarning = () =>
		!['active', 'trialing', 'past_due'].includes(clubData.status)

	const pastDueWarning = () => clubData.status == 'past_due'

	const [bannerHash, setBannerHash] = useState(null)
	const [bannerLoading, setBannerLoading] = useState(false)

	const bannerInputRef = React.createRef()

	const handleNewBannerClick = e => {
		bannerInputRef.current.click()
	}

	const handleBannerChanged = async e => {
		setBannerLoading(true)
		try {
			/** @type {File} */
			const file = e.target.files[0]
			if (!file) return

			const fileData = await fileToBase64(file)

			console.log('uploading file')
			const res = await firebase.functions().httpsCallable('uploadClubBanner')({
				club: clubId,
				imageBase64: fileData
			})
			console.log(res.data.fileName)
			setTimeout(() => {
				setBannerLoading(false)
				setBannerHash(Date.now())
			}, 2000)
		} catch (err) {
			setBannerLoading(false)
			console.error(err)
		}
	}

	const windowWidth = useWindowWidth()

	const [showingMassEmailModal, setShowingMassEmailModal] = useState(false)

	const [adding, setAdding] = useState(false)

	const addBlankMember = async () => {
		setAdding(true)
		const res = await firebase.functions().httpsCallable('createMember')({
			club: clubId,
			email: undefined,
			card: undefined
		})
		console.log(res)
		setAdding(false)
		history.push(`/app/club/${clubId}/${res.data.id}`)
	}

	if (!clubData) {
		if (loadingClub) return <ClubLoadingSkeleton />

		if (localStorage.getItem('last_club_id') === clubId)
			localStorage.removeItem('last_club_id')
		return <NoClubFound />
	}

	const actualClub = (
		<VcfUploadOverlay onDropCards={addVcfMembers}>
			<ClubBanner
				owner={clubData.owner}
				clubId={clubId}
				hash={bannerHash}
				loading={bannerLoading}
				isAdministrator={true}
			>
				<Menu size='huge' secondary inverted className='banner-menu'>
					<Menu.Item className='club-title'>
						<h1>{clubData ? clubData.name : '...'}</h1>
					</Menu.Item>
					<Menu.Menu position='right'>
						<Menu.Item>
							<Modal
								style={{ maxWidth: '400px' }}
								closeIcon
								open={showingMassEmailModal}
								onClose={() => setShowingMassEmailModal(false)}
							>
								<SendMassEmail clubId={clubId} club={club} />
							</Modal>
							<OverflowButtonGroup
								limit={windowWidth < 768 ? 0 : 3}
								inverted
								basic
								buttons={[
									{
										content: 'Send Email',
										icon: 'envelope',
										onClick: () => setShowingMassEmailModal(true)
									},
									{
										as: Link,
										to: `/app/club/${clubId}/settings`,
										icon: 'setting',
										content: 'Settings'
									},
									{
										as: Link,
										to: '/c/' + clubId,
										content: 'Public Version',
										icon: 'chain',
										target: '_blank'
									},
									{
										as: Link,
										to: `/app/club/${clubId}/print`,
										icon: 'print',
										content: 'Print'
									},
									<Modal
										trigger={<Dropdown.Item icon='qrcode' content='QR Code' />}
										closeIcon
									>
										<QRCode club={clubId} size={300} transparent />
									</Modal>
								]}
							/>
						</Menu.Item>
					</Menu.Menu>
				</Menu>
				<div className='banner-upload'>
					<Menu inverted secondary size='large'>
						<Menu.Item
							icon='picture'
							content='Upload New Banner'
							onClick={handleNewBannerClick}
						/>
						<input
							type='file'
							ref={bannerInputRef}
							onChange={handleBannerChanged}
						/>
					</Menu>
				</div>
			</ClubBanner>
			<Message hidden={!pastDueWarning()} icon warning>
				<Icon name='warning sign' />
				<Message.Content>
					<p>
						The payment for your club is past due. Soon, your club will be
						disabled until payment is completed.
					</p>
					<p>
						It's likely that you can fix this by going to{' '}
						<b>Settings > Payment > Open Latest Invoice</b> and paying your
						outstanding balance there.
					</p>
				</Message.Content>
			</Message>
			<Message hidden={!payWarning()} icon negative>
				<Icon name='warning sign' />
				<Message.Content>
					<Message.Header>
						There&apos;s a problem with this club&apos;s payment
					</Message.Header>

					<p>
						Either the subscription for this club has been cancelled, or payment
						hasn&apos;t been completed. Until payment is completed:
					</p>

					<Message.List>
						<Message.Item></Message.Item>
						<Message.Item>
							You won&apos;t be able to make changes to the club or its members.
						</Message.Item>
						<Message.Item>
							Your club members won&apos;t be able to view this club&apos;s
							data, or update their information.
						</Message.Item>
					</Message.List>
					<p>
						It's likely that you can fix this by going to{' '}
						<b>Settings > Payment > Open Latest Invoice</b> and paying your
						outstanding balance there.
					</p>
				</Message.Content>
			</Message>

			<Dimmer.Dimmable>
				<StatSegment
					values={[
						[memberCount(), 'Complete'],
						[
							clubData.sortedLists.filtered
								? 0
								: clubData.sortedLists.incomplete.length,
							'Incomplete'
						],
						[
							clubData.sortedLists.filtered
								? 0
								: clubData.sortedLists.blank.length,
							'Blank'
						]
					]}
				/>

				<MemberDisplay
					match={match}
					filter={filter}
					setFilter={setFilter}
					loading={loadingClub}
					sortedLists={clubData.sortedLists}
					showIncomplete={true}
					loadMember={loadMember}
					updateMember={updateMember}
					uploadMemberImage={uploadMemberImage}
					deleteMemberImage={deleteMemberImage}
					clubId={clubId}
					clubOwner={clubData.owner}
					addBlankMember={addBlankMember}
					adding={adding}
					emptyComponent={
						<Segment placeholder>
							<Header icon>
								<Icon name='user outline' />
							</Header>
							<p style={{ textAlign: 'center' }}>
								Looks like you don't have any completed contacts in this club
								yet.
							</p>
							<Button primary loading={adding} onClick={addBlankMember}>
								Create A Contact
							</Button>
						</Segment>
					}
				/>

				<Dimmer active={payWarning()} inverted />
			</Dimmer.Dimmable>

			{/* development ONLY */}
			{/* <DebugMemberList club={clubId} /> */}
			<CodeBlock>{JSON.stringify(clubData, null, 2)}</CodeBlock>
		</VcfUploadOverlay>
	)

	return (
		<Switch>
			<Route
				exact
				path={'/app/club/:clubUrl/print'}
				render={props => (
					<PrintVersion
						{...props}
						clubReference={clubReference}
						clubData={clubData}
						clubId={clubId}
					/>
				)}
			/>
			<Route
				exact
				path={'/app/club/:clubUrl/settings'}
				render={props => (
					<ClubSettings
						{...props}
						clubReference={clubReference}
						clubData={clubData}
						clubId={clubId}
					/>
				)}
			/>
			<Route
				exact
				path={'/app/club/:clubUrl/confirm_delete/:deleteKey'}
				render={props => (
					<ConfirmDelete
						{...props}
						clubReference={clubReference}
						clubData={clubData}
						clubId={clubId}
					/>
				)}
			/>
			<Route
				path={['/app/club/:clubUrl', '/app/club/:clubUrl/:memberId']}
				exact
				render={() => actualClub}
			/>
			<Route component={NotFound404} />
		</Switch>
	)
}

export default UserClub
