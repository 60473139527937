import 'firebase/firestore'
import 'firebase/auth'

import './PrintVersion.less'

import * as firebase from 'firebase/app'
import React, { useEffect, useMemo, useState } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { Dropdown, Header, Icon, Loader, Menu } from 'semantic-ui-react'
import VCard from 'vcfer'

import CodeBlock from '../../../components/CodeBlock'
import ViewMemberPrint from '../../../components/ViewMemberPrint/ViewMemberPrint'
import vutil from '../../../util/vutil'

/**
 * @param {object} props
 * @param {firebase.firestore.DocumentReference} props.clubReference
 * @param {ClubDocument} props.clubData
 * @param {string} props.clubId
 */
const PrintVersion = ({ clubReference, clubData, clubId }) => {
	const [members, loading, error] = useCollectionDataOnce(
		clubReference
			.collection('members')
			.where('owner', '==', firebase.auth().currentUser.uid),
		{
			idField: 'id'
		}
	)

	useEffect(() => {
		console.log('print version loaded members:', members)
	}, [members, loading])

	const sortedMembers = useMemo(() => {
		if (!members) return null

		return members
			.filter(m => {
				const card = new VCard(JSON.parse(m.card))
				return vutil(card).isValid()
			})
			.sort((a, b) => {
				const aCard = new VCard(JSON.parse(a.card))
				const bCard = new VCard(JSON.parse(b.card))

				return vutil(aCard).compare(bCard)
			})
	}, [members])

	if (loading)
		return (
			<div>
				<Loader />
			</div>
		)

	if (error)
		return <CodeBlock alwaysShow>{JSON.stringify(error, null, 2)}</CodeBlock>

	return (
		<PrintVersionDisplay
			members={sortedMembers}
			club={clubData}
			clubId={clubId}
		/>
	)
}

export default PrintVersion

const optionsColumns = [1, 2, 3, 4].map(v => ({
	key: v - 1,
	text: v + ' Column' + (v == 1 ? '' : 's'),
	value: v
}))

const optionsPicture = [
	{
		key: 0,
		text: 'Small Picture',
		value: { thumb: 25, size: 25 }
	},
	{
		key: 1,
		text: 'Medium Picture',
		value: { thumb: 50, size: 50 }
	},
	{
		key: 2,
		text: 'Large Picture',
		value: { thumb: 250, size: 75 }
	}
]

const optionsHeader = [
	{ key: 'show', text: 'Show Header', value: true },
	{ key: 'show', text: 'Hide Header', value: false }
]

/**
 *
 * @param {object} props
 * @param {ClubDocument} props.club
 */
const PrintVersionDisplay = ({ members, club, clubId }) => {
	const [columns, setColumns] = useState(optionsColumns[2].value)
	const [picture, setPicture] = useState(optionsPicture[0].value)
	const [header, setHeader] = useState(optionsHeader[0].value)

	return (
		<div id='print-version'>
			<Menu id='print-menu'>
				<Dropdown
					floating
					item
					trigger={
						<span>
							<Icon name='columns' /> Columns
						</span>
					}
					header='Column Count'
					options={optionsColumns}
					value={columns}
					defaultValue={columns}
					onChange={(e, { value }) => {
						setColumns(value)
					}}
				/>
				<Dropdown
					floating
					item
					trigger={
						<span>
							<Icon name='user circle outline' /> Picture Size
						</span>
					}
					header='Picture Size'
					options={optionsPicture}
					value={picture}
					defaultValue={picture}
					onChange={(e, { value }) => {
						console.log(value)
						setPicture(value)
					}}
				/>
				<Dropdown
					floating
					item
					trigger={
						<span>
							<Icon name='window maximize outline' />
							Header
						</span>
					}
					header='Header Display'
					options={optionsHeader}
					value={header}
					defaultValue={header}
					onChange={(e, { value }) => setHeader(value)}
				/>
				<Menu.Menu position='right'>
					<Menu.Item onClick={() => window.print()}>
						<Icon name='print' />
						Print
					</Menu.Item>
				</Menu.Menu>
			</Menu>

			{header && <div id='print-header'>{club.name}</div>}

			<div
				className='print-members'
				style={{
					columnCount: columns
				}}
			>
				{members.map(member => (
					<ViewMemberPrint
						key={member.editUrl}
						member={member}
						// memberId={member.id}
						clubId={clubId}
						thumbSize={picture.thumb}
						size={picture.size}
					/>
				))}
			</div>

			{/* <div id='fixed-footer'>Generated by Circlus</div> */}
		</div>
	)
}
