import './MemberDisplay.less'

import React, { useEffect, useState } from 'react'
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { Loader } from 'semantic-ui-react'
import VCard from 'vcfer'

import EditMember from '../EditMember2/EditMember'
import ViewMember from '../ViewMember2/ViewMember'
import MemberList from './MemberList'

/** @type {React.Context<IMemberDisplayContext>} */
export const MemberDisplayContext = React.createContext()

export const useMemberDisplayContext = () =>
	React.useContext(MemberDisplayContext)

/**
 *
 * Generic display for members, to be used in UserClub
 * and regular clubs.
 *
 *
 * **Note**: on dekstop, delesecting a member is impossible.
 * *Not so on mobile...*
 *
 * @param {IMemberDisplayContext & {match: any}} props
 */
const MemberDisplay = ({
	match,
	filter,
	setFilter,
	loading,
	sortedLists,
	showIncomplete,
	loadMember,
	updateMember,
	uploadMemberImage,
	deleteMemberImage,
	clubId,
	clubOwner,
	emptyComponent,
	addBlankMember,
	adding
}) => {
	const [selectedMember, setSelectedMember] = useState()

	const { clubUrl, memberId } = match.params

	const location = useLocation()

	useEffect(() => {
		console.log('locaiton change', match.params)
		setSelectedMember(memberId)
	}, [location])

	const reloadSelectedMember = () => {
		setSelectedMember(selectedMember)
	}
	// TODO unneeded card parsing here, now done inside of EditMember
	// instead.
	const [loadedMember, setLoadedMember] = useState({
		loading: false,
		member: undefined,
		card: undefined
	})

	useEffect(() => {
		if (!selectedMember) return
		setLoadedMember({
			loading: true,
			member: undefined,
			card: undefined
		})
		loadMember(selectedMember).then(data => {
			if (!data || data.error) return
			console.log(data)
			setLoadedMember({
				loading: false,
				member: data,
				card: new VCard(JSON.parse(data.card))
			})
		})
	}, [selectedMember])

	const history = useHistory()

	const onSelectMember = memberId => {
		// setSelectedMember(memberId)
		history.push(`${basePath}/${memberId}`)
	}

	const onDeselectMember = () => {
		setSelectedMember(null)
	}

	const basePath = updateMember ? `/app/club/${clubId}` : `/c/${clubId}`

	const desktop = (
		<>
			<MemberDisplayContext.Provider
				value={{
					filter,
					setFilter,
					loading,
					sortedLists,
					selectedMember,
					setSelectedMember,
					reloadSelectedMember,
					onSelectMember,
					onDeselectMember,
					showIncomplete,
					loadMember,
					updateMember,
					clubId,
					clubOwner,
					basePath,
					emptyComponent,
					addBlankMember,
					adding
				}}
			>
				<div className={`member-display ${selectedMember ? 'selected' : ''}`}>
					<div className='member-display-left'>
						{loading ? <Loader active /> : <MemberList />}
					</div>
					<div className='member-display-right'>
						<StickyBox offsetTop={10} offsetBottom={10}>
							{!selectedMember ? null : loadedMember.loading ||
							  !loadedMember.member ? (
								<Loader active inline='centered' />
							) : updateMember ? (
								<EditMember
									clubId={clubId}
									member={loadedMember.member}
									className='member-sticky'
									handleSubmit={updateMember}
									uploadMemberImage={uploadMemberImage}
									deleteMemberImage={deleteMemberImage}
									useTimer={4}
								/>
							) : (
								<ViewMember
									member={loadedMember.member}
									clubId={clubId}
									className='member-sticky'
								/>
							)}
						</StickyBox>
					</div>
				</div>
			</MemberDisplayContext.Provider>
		</>
	)

	const mobile = <></>

	// TODO - mobile interface
	// return isMobile ? mobile : desktop;
	return desktop
}

export default MemberDisplay
