import React from 'react'
import { Label } from 'semantic-ui-react'

import { useMemberDisplayContext } from './MemberDisplay'

const BlankEntriesList = ({ blanks }) => {
	const { onSelectMember, selectedMember } = useMemberDisplayContext()

	return (
		<div className='label-entry-list'>
			{blanks.map((blank, i) => {
				const isSelected = selectedMember === blank.id

				return (
					<Label
						basic
						circular
						size='large'
						key={i}
						onClick={() => onSelectMember(blank.id)}
						className={'entry-label ' + (isSelected ? 'selected' : null)}
					>
						{i + 1}
					</Label>
				)
			})}
		</div>
	)
}

export default BlankEntriesList
