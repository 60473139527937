import './Storefront.less'

import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { NavHashLink as NavLink } from 'react-router-hash-link'
import { Button, Menu } from 'semantic-ui-react'

import Brand from '../components/Brand'
import FullPagePadder from '../components/FullPagePadder'
import { useAuthContext } from '../hooks/AuthHook'
import NotFound404 from './NotFound404'
import About from './other/About'
import ForgotPassword from './other/ForgotPassword'
import TermsOfUse from './other/TermsOfUse'
import Features from './storefront/Features'
import Home from './storefront/Home'
import Pricing from './storefront/Pricing'

const Storefront = ({ match }) => {
	return (
		<div className='storefront'>
			<div className='storefront-width-wrap'>
				<NavStorefront />
			</div>
			<Switch>
				<Route path='/' exact component={StorefrontSpreads} />
				{/* <Route path='/pricing' exact component={Pricing} />
				<Route path='/features' exact component={Features} /> */}
				<Route exact path='/about' component={About} />
				<Route exact path='/terms' component={TermsOfUse} />
				<Route exact path='/forgot-password' component={ForgotPassword} />
				<Route component={NotFound404} />
			</Switch>
			<div className='storefront-width-wrap'>
				<StorefrontFooter />
			</div>
		</div>
	)
}

export default Storefront

const StorefrontSpreads = () => (
	<>
		<FullPagePadder shadowTop shadowBottom background>
			<Home />
		</FullPagePadder>
		<FullPagePadder id='features'>
			<Features />
		</FullPagePadder>
		<FullPagePadder
			subtractFooter
			shadowTop
			shadowBottom
			background
			id='pricing'
		>
			<Pricing />
		</FullPagePadder>
	</>
)

const StorefrontMenuItem = ({ children, to, anchor, className }) => {
	return (
		<Menu.Item className={className}>
			{anchor ? (
				<a href={anchor} className={'menu-item'}>
					{children}
				</a>
			) : (
				<NavLink
					className={'menu-item'}
					activeClassName='menu-item-active'
					to={to}
				>
					{children}
				</NavLink>
			)}
		</Menu.Item>
	)
}

const NavStorefront = () => {
	const { user, signOut } = useAuthContext()

	return (
		<Menu secondary stackable className='storefront-nav'>
			<StorefrontMenuItem to='/'>
				<Brand />
			</StorefrontMenuItem>
			<StorefrontMenuItem anchor='/#features'>Features</StorefrontMenuItem>
			<StorefrontMenuItem anchor='/#pricing'>Pricing</StorefrontMenuItem>
			<StorefrontMenuItem anchor='docs/#/faq'>FAQ</StorefrontMenuItem>
			{/* <StorefrontMenuItem to='/theming'>Theming</StorefrontMenuItem> */}
			<Menu.Menu position='right'>
				{!!user ? (
					<>
						<Menu.Item>
							<NavLink to='/app'>
								<Button primary>Open App</Button>
							</NavLink>
						</Menu.Item>
						<Menu.Item onClick={signOut}>Log Out</Menu.Item>
					</>
				) : (
					<StorefrontMenuItem to='/login'>
						<Button basic>
							<span id='login-button'>Log In</span>
						</Button>
					</StorefrontMenuItem>
				)}
			</Menu.Menu>
		</Menu>
	)
}

const StorefrontFooter = () => {
	return (
		<div id='storefront-footer'>
			<span>&copy; Circlus 2020</span>
			<span>
				<Link to='/about' target='_blank'>
					About
				</Link>
			</span>
			<span>
				<Link to='/docs/#/faq' target='_blank'>
					FAQ
				</Link>
			</span>
			<span>
				<Link to='/docs/#/tos' target='_blank'>
					Terms of Service
				</Link>
			</span>
			<span>
				<a href='mailto:feedback@circl.us'>Feedback</a>
			</span>
			<span>
				<a href='mailto:support@circl.us'>Support</a>
			</span>
		</div>
	)
}
