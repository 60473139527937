import React, { useCallback, useEffect, useState } from 'react'
import { Button, Icon, Input, Transition } from 'semantic-ui-react'
import { Property } from 'vcfer'

import { escapeText, unescapeText } from '../../util'
import AddButton from './AddButton'
import { useEditMemberContext } from './EditMember'
import TypePicker from './TypePicker'

/**
 * @typedef PropHandlerOptions
 *
 * @property {string} prop
 * @property {string} name
 * @property {number} semicolonIndex
 * @property {string[][]} pickerTypes
 * @property {string[]} pickerTypesExclude
 * @property {boolean} pickerModifiesSemicolonIndex
 * @property {boolean} isSemicolonSubset
 * @property {any} component
 * @property {boolean} singleton
 * @property {boolean} required
 * @property {boolean} addButton
 * @property {boolean} other
 * @property {() => Property} defaultProp
 */

/** @type {React.Context<PropHandlerOptions & { hasSemicolonIndex: boolean}>} */
const PropHandlerContext = React.createContext()

export const usePropHandlerContext = () => React.useContext(PropHandlerContext)

/** @type {React.FC<PropHandlerOptions>} */
const PropHandler = props => {
	const {
		prop,
		name,
		semicolonIndex,
		pickerTypes,
		pickerTypesExclude,
		pickerModifiesSemicolonIndex = false,
		isSemicolonSubset = false,
		component,
		singleton = false,
		required = false,
		alwaysVisible = false,
		addButton = false,
		other = false,
		defaultProp = (() => new Property(prop, '', { value: 'text' }))
	} = props

	const hasSemicolonIndex = semicolonIndex || semicolonIndex === 0

	const {
		card,
		inHeader,
		blankPropsShown,
		setBlankPropsShown,
		updateEditMember,
		addToOtherMenu,
		showBlankProp,
		showingBlankProp
	} = useEditMemberContext()

	useEffect(() => {
		if (other && !addButton)
			addToOtherMenu({
				prop,
				name,
				defaultProp,
				singleton,
				semicolonIndex,
				isSemicolonSubset
			})
	}, [])

	if (!card.has(prop) && !(required || alwaysVisible || addButton)) return null

	if (!card.has(prop) && (required || alwaysVisible)) {
		card.add(defaultProp())
	}

	if (
		singleton &&
		hasSemicolonIndex &&
		isSemicolonSubset &&
		!(required || alwaysVisible) &&
		!showingBlankProp(prop, semicolonIndex) &&
		card.getOne(prop).value.split(';')[semicolonIndex]
	) {
		showBlankProp(prop, semicolonIndex)
	}

	if (
		singleton &&
		hasSemicolonIndex &&
		isSemicolonSubset &&
		!showingBlankProp(prop, semicolonIndex) &&
		!required &&
		!alwaysVisible &&
		!card.getOne(prop).value.split(';')[semicolonIndex]
	) {
		// console.log('not rendering', name)
		// console.log(blankPropsShown)
		return null
	}

	const InputComponent = component || PropInputComponentGeneric

	/** only iterate if it's not a singleton */
	const propIterator = c => {
		if (singleton) return c(card.getOne(prop), null)
		else
			return card
				.get(prop)
				.filter(property => {
					if (!hasSemicolonIndex) return true
					return property.value.split(';')[semicolonIndex] === ''
				})
				.map(c)
	}

	return (
		<PropHandlerContext.Provider value={{ ...props, hasSemicolonIndex }}>
			<div className='prop-handler'>
				{card.has(prop) && !inHeader && (
					<div className='prop-handler-header'>{name}</div>
				)}
				<div className='props'>
					{propIterator((property, i) => (
						<div className='prop' key={i}>
							{!inHeader && !required ? <DeleteButton prop={property} /> : null}
							{/* {pickerTypes ? <TypePicker prop={property} /> : null} */}
							<div className='input-component'>
								<InputComponent
									prop={property}
									typePicker={
										pickerTypes ? <TypePicker prop={property} /> : null
									}
								/>
							</div>
						</div>
					))}
				</div>
				{addButton && (
					<AddButton
						onClick={e => {
							card.add(defaultProp())
							updateEditMember()
						}}
						icon='plus circle'
						content={`Add ${name}`}
						iconColor='green'
					/>
				)}
			</div>
		</PropHandlerContext.Provider>
	)
}

/** @type {React.FC<{ prop: Property }>} */
const DeleteButton = ({ prop }) => {
	const [confirm, setConfirm] = useState(false)

	const confirmRef = React.useRef()

	const { card, updateEditMember } = useEditMemberContext()

	useEffect(() => {
		if (confirm) confirmRef.current.focus()
	}, [confirm])

	const handleClick = e => {
		document.activeElement.blur()
		card.remove(prop)
		updateEditMember()
	}

	return (
		<div className={'delete-button ' + (confirm ? 'confirm' : null)}>
			{confirm ? (
				<Button
					color='red'
					onClick={handleClick}
					ref={confirmRef}
					onBlur={() => setConfirm(false)}
					fluid
				>
					Delete
				</Button>
			) : (
				<Icon
					name='minus circle'
					size='large'
					color='red'
					onClick={() => setConfirm(true)}
				/>
			)}
		</div>
	)
}

/** @type {React.FC<{prop: Property}>} */
const PropInputComponentGeneric = ({ prop, typePicker }) => {
	const { updateEditMember, hasRequiredError } = useEditMemberContext()
	const {
		name,
		semicolonIndex,
		hasSemicolonIndex,
		required
	} = usePropHandlerContext()

	const handleChange = (e, { value }) => {
		if (hasSemicolonIndex) {
			// TODO: allow entering semicolons
			if (value.includes(';')) return
			const v = prop.value.split(';')
			v[semicolonIndex] = escapeText(value)
			prop.value = v.join(';')
		} else {
			prop.value = value
		}
		updateEditMember()
	}

	const val = unescapeText(
		hasSemicolonIndex ? prop.value.split(';')[semicolonIndex] : prop.value
	)

	return (
		<>
			<Input
				size='small'
				fluid
				placeholder={name}
				value={val}
				onChange={handleChange}
				error={required && hasRequiredError()}
			>
				{typePicker}
				<input autoComplete='off' />
			</Input>
		</>
	)
}

export default PropHandler
