import React, { Component, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuthContext } from '../hooks/AuthHook'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
	const { user, loadingUser } = useAuthContext()

	const render = props => {
		if (loadingUser) return null
		return !!user ? (
			<Component {...props} />
		) : (
			<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
		)
	}
	//<Redirect to={{pathname: '/login', state: {from: props.location}}} />

	return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute
