import 'semantic-ui-less/semantic.less'

import './index.less'

import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import config from './firebase-config.js'
import Main from './Main'
import * as serviceWorker from './serviceWorker'

// import * as firebase from 'firebase/app';
// import 'firebase/auth';

// firebase.initializeApp(config);
config()

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
