import './FullPagePadder.less'

import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

const FullPagePadder = ({
	children,
	id,
	subtractMenu,
	subtractFooter,
	shadowTop,
	shadowBottom,
	background,
	style
}) => {
	const m = (
		<div
			className={[
				'full-page-padder',
				subtractMenu && 'subtract-menu',
				subtractFooter && 'subtract-footer',
				background && 'has-background'
			].join(' ')}
			style={
				typeof background === 'string'
					? {
							background: background
					  }
					: undefined
			}
		>
			{shadowTop && <div className='shadow-caster top' />}
			<div className='full-page-padder-contents' style={style}>
				{children}
			</div>
			{shadowBottom && <div className='shadow-caster bottom' />}
		</div>
	)

	return id ? <ScrollableAnchor id={id}>{m}</ScrollableAnchor> : m
}

export default FullPagePadder
