import './VcfUploadOverlay.less'

import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Header, Icon, Loader } from 'semantic-ui-react'
import VCard from 'vcfer'

// TODO - fix drag and drop sizing
const VcfUploadOverlay = ({ children, onDropCards }) => {
	const [loading, setLoading] = useState(false)

	const _onDrop = useCallback(
		acceptedFiles => {
			console.log(acceptedFiles)
			let newMembers = []
			let loadingCount = 0
			setLoading(true)
			acceptedFiles.forEach(file => {
				const reader = new FileReader()
				reader.onabort = () => console.log('file reading aborted')
				reader.onerror = () => console.log('file reading error')
				reader.onload = () => {
					const cards = VCard.fromMultiCardString(reader.result)
					newMembers = [...newMembers, ...cards]
					console.log(cards.map(c => c.toJCard()))
					loadingCount--
					if (loadingCount <= 0) {
						console.log('running drop event...')
						onDropCards(newMembers).then(res => {
							setLoading(false)
							console.log('done', res)
						})
					}
				}
				loadingCount++
				reader.readAsText(file)
			})
		},
		[onDropCards]
	)

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept
	} = useDropzone({
		onDrop: _onDrop,
		accept: 'text/x-vcard, text/vcard'
	})

	return (
		<div
			className='vcf-upload-root'
			{...getRootProps({
				onClick: e => e.stopPropagation()
			})}
		>
			{children}
			<input {...getInputProps()} />
			{isDragActive || loading ? (
				<div className='vcf-upload-overlay'>
					<div className='vcf-upload-overlay-border'>
						{loading ? (
							<Loader active inverted size='massive'>
								Creating...
							</Loader>
						) : (
							<>
								<Icon name='address card outline' size='massive' inverted />
								<Header inverted>Create from vCard</Header>
							</>
						)}
					</div>
				</div>
			) : null}
		</div>
	)
}

export default VcfUploadOverlay
