import * as firebase from 'firebase/app'
import React, { useEffect, useReducer, useState } from 'react'
import { toast } from 'react-semantic-toasts'

// const projectId = firebase.app().options.projectId;
const projectId = process.env.REACT_APP_PROJECT_NAME
const projectHost = process.env.REACT_APP_PROJECT_HOST
const baseUrl =
	process.env.NODE_ENV === 'development'
		? `http://localhost:5001/circlus-development/us-central1/visitorApi`
		: `https://${projectHost}-${projectId}.cloudfunctions.net/visitorApi`

/**
 @type {React.Context<IVisitorContext>}
*/
export const VisitorContext = React.createContext()

export const useVisitorContext = () => React.useContext(VisitorContext)

export const VisitorProvider = ({ clubUrl, editUrl, children }) => {
	const token = () => {
		if (editUrl) return localStorage.getItem(editUrl)
		else return localStorage.getItem(clubUrl)
	}
	const [authenticated, setAuthenticated] = useState(!!token())

	const requestToken = async pass => {
		const body = {
			club: clubUrl,
			pass,
			editUrl
		}
		const url = baseUrl + (editUrl ? '/editor_token' : '/visitor_token')
		console.log('Requesting ' + url)
		console.log(body)
		const res = await fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then(fetchRes => fetchRes.json())
		if (res.token) {
			console.log('obtained token!')
			localStorage.setItem(clubUrl, res.token)
			if (editUrl) localStorage.setItem(editUrl, res.token)
			setAuthenticated(true)
			return true
		}
		if (res.error) throw new Error('requestToken error: ' + res.error)
	}

	const [joiningWithoutPass, setJoiningWithoutPass] = useState(!authenticated)
	// try to auto-connect without a password.
	// used for clubs with password auth disabled.
	useEffect(() => {
		if (!authenticated)
			requestToken('').catch(err => setJoiningWithoutPass(false))
	}, [authenticated])

	const refreshToken = async () => {
		const res = await fetch(baseUrl + '/refresh_token', {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(fetchRes => fetchRes.json())
		if (res.token) {
			console.log('refreshed token!')
			localStorage.setItem(clubUrl, res.token)
			if (editUrl) localStorage.setItem(editUrl, res.token)
			return true
		}
		if (res.error) {
			console.error('refreshToken error: ' + res.error)
			return false
		}
	}

	/**
	 *
	 * @param {string} method
	 * @param {string} url
	 * @param {object} body
	 *
	 * @returns {object}
	 */
	const clubApiCall = async (
		method,
		url,
		body,
		contentType = 'application/json'
	) => {
		const clubApiUrl = baseUrl + '/clubs/' + clubUrl

		const makeCall = () =>
			fetch(clubApiUrl + url, {
				method,
				body: body ? body : undefined,
				headers: {
					'Content-Type': contentType,
					Authorization: `Bearer ${token()}`
				}
			}).then(fetchRes => fetchRes.json())

		let res = await makeCall()

		if (res.error && res.error.includes('jwt expired')) {
			const didRefresh = await refreshToken()
			setAuthenticated(didRefresh)
			if (didRefresh) {
				res = await makeCall()
			}
		}

		if (res.error && !res.error.includes('jwt expired')) {
			toast({
				icon: 'warning circle',
				title: 'API Error',
				description: res.error,
				color: 'red',
				time: 0
			})
			console.error('clubApiCall Error: ' + res.error)
			return
		}
		return res
	}

	const apiUsers = []

	const useApiData = url => {
		const initialState = {
			loading: true,
			error: undefined,
			value: undefined
		}

		const reducer = (state, action) => {
			switch (action.type) {
				case 'error':
					return {
						loading: false,
						error: action.error,
						value: undefined
					}
				case 'reset':
					return initialState
				case 'value':
					return {
						loading: false,
						error: undefined,
						value: action.value
					}
				default:
					return state
			}
		}

		const [state, dispatch] = useReducer(reducer, initialState)
		apiUsers.push(dispatch)

		if (state.loading)
			clubApiCall('GET', url, null)
				.then(res => dispatch({ type: 'value', value: res }))
				.catch(err => dispatch({ type: 'error', error: err }))

		return [state.value, state.loading, state.error]
	}

	const reload = () => {
		for (const dispatch of apiUsers) {
			if (dispatch) dispatch({ type: 'reset' })
		}
	}

	// useEffect(() => {
	// 	reload();
	// }, [authenticated]);

	useEffect(() => {
		const token = localStorage.getItem(clubUrl)
		if (!token) {
			setAuthenticated(false)
			console.log('no token')
			return
		}
	}, [clubUrl])

	return (
		<VisitorContext.Provider
			value={{
				clubUrl,
				token,
				requestToken,
				authenticated,
				clubApiCall,
				useApiData,
				joiningWithoutPass
			}}
		>
			{children}
		</VisitorContext.Provider>
	)
}
