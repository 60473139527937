import firebase from 'firebase'
import React, { useCallback, useState } from 'react'
import { Button, Dimmer, Icon, Image, Menu, Popup } from 'semantic-ui-react'

import { memberImageUrl } from '../../util'
import { useEditMemberContext } from './EditMember'

const EditMemberPicture = ({ owner, clubId, memberId }) => {
	const { uploadMemberImage, deleteMemberImage } = useEditMemberContext()

	// forces refresh of background image
	const randomExt = () =>
		memberImageUrl(owner, clubId, memberId, 250) +
		`&rand=${new Date().getTime()}`

	const [img, setImg] = useState(randomExt())

	const forceImageReload = () => setImg(randomExt())

	/**
	 * 0: nothing
	 * 1: spinner
	 * 2: checkmark and spinner
	 * 3: deletion mark and spinner
	 * 4: error x and spinner
	 */
	const [isUploading, setIsUploading] = useState(0)

	const fileInputRef = React.createRef()

	const handleFileChange = async e => {
		setIsUploading(1)
		try {
			await uploadMemberImage(e.target.files[0], owner, clubId, memberId)
			setIsUploading(2)
		} catch (error) {
			console.error(error)
			setIsUploading(4)
		}
		setTimeout(() => {
			forceImageReload()
			setIsUploading(0)
		}, 5000)
	}

	const actualPicture = (
		<Dimmer.Dimmable
			className='edit-member-image-dimmer'
			dimmed={isUploading !== 0}
		>
			<Dimmer active={isUploading !== 0}>
				<Icon.Group>
					{isUploading != 0 ? (
						<Icon
							loading
							fitted
							size='huge'
							name='circle notch'
							color={[null, null, 'grey'][isUploading]}
						/>
					) : null}
					{isUploading != 1 ? (
						<Icon
							fitted
							name={['upload', null, 'check', 'trash', 'x'][isUploading]}
							color={[null, null, 'green', null, 'red'][isUploading]}
						/>
					) : null}
				</Icon.Group>
			</Dimmer>
			<div
				className='edit-member-image'
				style={{
					background: `url(${img}), url('/avatar.jpg')` // TODO better avatar
				}}
			></div>
			<input
				className='edit-member-image-file-input'
				type='file'
				name='file'
				onChange={handleFileChange}
				ref={fileInputRef}
			/>
		</Dimmer.Dimmable>
	)

	const handleUpload = () => {
		fileInputRef.current.click()
	}

	const handleDelete = async () => {
		setIsUploading(1)
		try {
			await deleteMemberImage(clubId, memberId)
			setIsUploading(3)
		} catch (err) {
			console.error(err)
			setIsUploading(4)
		}
		setTimeout(() => {
			forceImageReload()
			setIsUploading(0)
		}, 1000)
	}

	return (
		<Popup trigger={actualPicture} hoverable position='right center'>
			<Menu vertical secondary fluid className='edit-member-popup'>
				<Menu.Item name='Upload' icon='upload' onClick={handleUpload} />
				<Menu.Item name='Delete' icon='trash' onClick={handleDelete} />
			</Menu>
		</Popup>
	)
}

export default EditMemberPicture
