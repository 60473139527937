import './SettingsMenu.less'

import React, { useMemo, useState } from 'react'
import { Grid, Header, Menu, Segment } from 'semantic-ui-react'

/**
 *
 * @param {object} params
 * @param {React.Fragment} params.header
 * @param {{[pageName: string]: React.FC}} params.pages
 * @param {string} params.defaultPage name of default page
 * @param {object} params.data data passed to each page
 *
 * @returns {React.FC}
 */
const SettingsMenu = ({ header, pages, defaultPage, data, className }) => {
	const [active, setActive] = useState(defaultPage)

	const ActivePage = useMemo(() => {
		return pages[active]
	})

	return (
		<div className={className + ' settings-menu'}>
			<Header as='h1'>{header}</Header>
			<Grid columns={2} divided>
				<Grid.Column width={3}>
					<Menu secondary vertical fluid>
						{Object.entries(pages).map(([key, val], i) => (
							<Menu.Item
								name={key}
								key={key}
								active={active === key}
								onClick={e => setActive(key)}
							/>
						))}
					</Menu>
				</Grid.Column>
				<Grid.Column stretched width={13}>
					<Segment>
						<ActivePage {...data} />
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	)
}

export default SettingsMenu
