import './Features.less'

import React from 'react'
import { Icon } from 'semantic-ui-react'

const Features = () => {
	return (
		<div className='features-wrap'>
			<div className='features'>
				<FeatureBox icon='wizard' title='Feature Name'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eros
					elit, cursus dictum lacus ac, pharetra hendrerit metus. Donec semper,
					nulla ac posuere consequat, mi ipsum interdum nibh, at scelerisque
					sapien eros nec lectus.
				</FeatureBox>
				<FeatureBox icon='wizard' title='Feature Name'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eros
					elit, cursus dictum lacus ac, pharetra hendrerit metus. Donec semper,
					nulla ac posuere consequat, mi ipsum interdum nibh, at scelerisque
					sapien eros nec lectus.
				</FeatureBox>
				<FeatureBox icon='wizard' title='Feature Name'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eros
					elit, cursus dictum lacus ac, pharetra hendrerit metus. Donec semper,
					nulla ac posuere consequat, mi ipsum interdum nibh, at scelerisque
					sapien eros nec lectus.
				</FeatureBox>
				<FeatureBox icon='wizard' title='Feature Name'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eros
					elit, cursus dictum lacus ac, pharetra hendrerit metus. Donec semper,
					nulla ac posuere consequat, mi ipsum interdum nibh, at scelerisque
					sapien eros nec lectus.
				</FeatureBox>
				<FeatureBox icon='wizard' title='Feature Name'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eros
					elit, cursus dictum lacus ac, pharetra hendrerit metus. Donec semper,
					nulla ac posuere consequat, mi ipsum interdum nibh, at scelerisque
					sapien eros nec lectus.
				</FeatureBox>
				<FeatureBox icon='wizard' title='Feature Name'>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eros
					elit, cursus dictum lacus ac, pharetra hendrerit metus. Donec semper,
					nulla ac posuere consequat, mi ipsum interdum nibh, at scelerisque
					sapien eros nec lectus.
				</FeatureBox>
			</div>
		</div>
	)
}

const FeatureBox = ({ icon, title, children }) => (
	<div className='feature'>
		<Icon name={icon} size='massive' />
		<div className='feature-card'>
			<div className='feature-title'>{title}</div>
			<p>{children}</p>
		</div>
	</div>
)

export default Features
