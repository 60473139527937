import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { VisitorProvider, useVisitorContext } from '../../hooks/VisitorAuthHook'
import ClubSplash from './ClubSplash'
import EditorView from './EditorView'
import PasswordView from './PasswordView'

const ClubPath = props => {
	const { component, match, editPath } = props
	if (editPath) console.log('Editor Path', match.params.editUrl)
	console.log(match)
	const SubComponent = component
	return (
		<VisitorProvider
			clubUrl={match.params.clubUrl}
			editUrl={editPath ? match.params.editUrl : undefined}
		>
			<PasswordView>
				<SubComponent {...props} />
			</PasswordView>
		</VisitorProvider>
	)
}

const ClubRouter = ({ match }) => {
	const route = (
		<Switch>
			{/* <ClubSplash /> */}
			<Route
				path={match.path + '/e/:editUrl'}
				exact
				render={props => (
					<ClubPath {...props} editPath component={EditorView} />
				)}
			/>
			<Route
				path={[match.path, match.path + '/:memberId']}
				exact
				render={props => <ClubPath {...props} component={ClubSplash} />}
			/>
		</Switch>
	)

	return route
}

export default ClubRouter
