import { isJSX } from '@babel/types'
import React, { useEffect } from 'react'
import { Button, Dropdown } from 'semantic-ui-react'

/**
 * @typedef OverflowButtonGroupType
 *
 * @property {number} limit
 * @property {number} maxWidth
 */

/**
 *
 * @param {import('semantic-ui-react').ButtonGroupProps & OverflowButtonGroupType} props
 */
const OverflowButtonGroup = ({
	limit = 3,
	buttons,
	maxWidth = 200,
	...props
}) => {
	const groupRef = React.createRef()

	useEffect(() => {
		if (groupRef.current) console.log(groupRef.current.clientWidth)
	}, [limit, maxWidth, groupRef])

	return (
		<Button.Group {...props} ref={groupRef} buttons={undefined}>
			{buttons
				.slice(0, limit)
				.map((s, i) =>
					React.isValidElement(s) ? (
						<div key={i}>{s}</div>
					) : (
						<Button key={i} {...s} />
					)
				)}
			<Dropdown
				button
				className='button icon'
				floating
				icon='ellipsis horizontal'
				compact={props.compact}
			>
				<Dropdown.Menu>
					{buttons
						.slice(limit, buttons.length)
						.map((v, i) =>
							React.isValidElement(v) ? (
								v
							) : (
								<Dropdown.Item key={i} {...v} inverted={false} />
							)
						)}
				</Dropdown.Menu>
			</Dropdown>
		</Button.Group>
	)
}

export default OverflowButtonGroup
