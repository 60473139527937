import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

import { useAppContext } from '../../hooks/AppHook'

const AppSplash = () => {
	const { userDoc } = useAppContext()

	const lastClub = localStorage.getItem('last_club_id')

	if (lastClub) return <Redirect to={`/app/club/${lastClub}`} />

	const clubIds = userDoc ? Object.keys(userDoc.ownedClubs) : null

	if (userDoc && clubIds.length > 0)
		return <Redirect to={`/app/club/${clubIds[0]}`} />

	return (
		<>
			<Segment placeholder>
				<Header icon>
					<Icon name='address book outline' />
					You haven't created a club yet.
				</Header>
				<p style={{ textAlign: 'center' }}>
					Start your first subscription now!
				</p>
				<Button size='big' primary as={Link} to='/app/new'>
					Create A Club
				</Button>
			</Segment>
		</>
	)
}

export default AppSplash
