import 'firebase/functions'

import * as firebase from 'firebase/app'
import React, { useState } from 'react'
import { Button, Loader, Progress, Segment } from 'semantic-ui-react'
import VCard from 'vcfer'

import { useMemberDisplayContext } from '../../../components/MemberDisplay/MemberDisplay'
import { parseCardsFromCsv } from '../../../util/createFromCsv'

const CreateFromCsv = () => {
	const { clubId } = useMemberDisplayContext()

	const fileInput = React.createRef()

	/** @type {[{email: string, card: VCard, error?: string}[], any]} */
	const [uploadData, setUploadData] = useState(null)
	const [uploading, setUploading] = useState(0)

	const handleSubmit = async () => {
		if (!uploadData) {
			console.error('no uploadData on handleSubmit')
			return
		}
		setUploading(1)

		const convertedData = uploadData.map(record => ({
			email: record.email,
			card: JSON.stringify(record.card.toJCard())
		}))

		try {
			await firebase.functions().httpsCallable('createBulkMembers')({
				club: clubId,
				members: convertedData
			})
			setUploading(2)
		} catch (err) {
			console.error(err)
			setUploading(3)
		}
	}

	/**
	 *
	 * @param {FileList} files
	 */
	const handleFileChange = async files => {
		setUploadData(
			parseCardsFromCsv(await files[0].text()).filter(record => !record.error)
		)
	}

	return (
		<div className='create-modal create-from-csv'>
			<h3>Import Contacts From Csv</h3>
			<Segment disabled={uploading}>
				<input
					type='file'
					ref={fileInput}
					onChange={e => handleFileChange(e.target.files)}
				/>
			</Segment>
			<Button
				size='large'
				positive={!!uploadData && !!uploadData.length}
				disabled={uploading == 2 || !uploadData || !uploadData.length}
				onClick={handleSubmit}
				loading={uploading == 1}
			>
				{uploadData
					? uploading == 2
						? 'Done!'
						: `Create ${uploadData.length} Contacts`
					: `Please Select a CSV File...`}
			</Button>
		</div>
	)
}

export default CreateFromCsv
