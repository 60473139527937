import 'firebase/functions'

import './MemberList.less'

import * as firebase from 'firebase/app'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	Button,
	Header,
	Icon,
	Input,
	List,
	Menu,
	Modal,
	Search,
	Sticky
} from 'semantic-ui-react'

import { useAppContext } from '../../hooks/AppHook'
import CreateFromCsv from '../../pages/app/UserClub/CreateFromCsv'
import CreateFromEmails from '../../pages/app/UserClub/CreateFromEmails'
import { MAX_FILTER_LENGTH } from '../../util'
import CodeBlock from '../CodeBlock'
import BlankEntriesList from './BlankEntriesList'
import IncompleteEntriesList from './IncompleteEntriesList'
import { useMemberDisplayContext } from './MemberDisplay'
import MemberListItem from './MemberListItem'

const MemberListMenu = ({ editVersion }) => {
	const {
		filter,
		setFilter,
		clubId,
		setSelectedMember,
		basePath,
		addBlankMember,
		adding
	} = useMemberDisplayContext()

	const history = useHistory()

	const searchRef = React.useRef()

	return (
		<Menu className='member-list-menu'>
			<Menu.Menu
				position='left'
				onClick={() => {
					searchRef.current.focus()
				}}
			>
				<Input
					fluid
					className='search'
					transparent
					value={filter}
					onChange={e => {
						setFilter(e.target.value.substr(0, MAX_FILTER_LENGTH))
					}}
				>
					<Icon name='search' />
					<input ref={searchRef} placeholder='Search...' />
					{filter ? <Icon name='x' link onClick={() => setFilter('')} /> : null}
				</Input>
			</Menu.Menu>
			{editVersion ? (
				<Menu.Menu position='right'>
					<Modal
						closeOnEscape={false}
						closeOnDimmerClick={true}
						closeIcon
						trigger={
							<Menu.Item style={{ padding: '0px 14px' }}>
								<Icon.Group>
									<Icon name='file excel' />
									<Icon corner name='add' />
								</Icon.Group>
							</Menu.Item>
						}
					>
						<CreateFromCsv />
					</Modal>
					<Modal
						closeOnEscape={false}
						closeOnDimmerClick={true}
						closeIcon
						trigger={
							<Menu.Item style={{ padding: '0px 14px' }}>
								<Icon.Group>
									<Icon name='envelope' />
									<Icon corner name='add' />
								</Icon.Group>
							</Menu.Item>
						}
					>
						<CreateFromEmails />
					</Modal>
					<Menu.Item style={{ paddingLeft: '15px' }} onClick={addBlankMember}>
						<Icon name={adding ? 'circle notch' : 'add'} loading={adding} />
					</Menu.Item>
				</Menu.Menu>
			) : null}
		</Menu>
	)
}

const MemberListSection = ({ letter }) => {
	const contextRef = useRef(null) // TODO what is this for?

	return (
		<div key={letter[0]} ref={contextRef}>
			{letter[0] ? (
				<List.Header className='letter-header'>{letter[0]}</List.Header>
			) : null}
			<List divided verticalAlign='middle'>
				{letter[1].map(member => (
					<MemberListItem member={member} key={member.id} />
				))}
			</List>
		</div>
	)
}

const MemberList = () => {
	const {
		filter,
		sortedLists,
		showIncomplete,
		onSelectMember,
		updateMember,
		emptyComponent
	} = useMemberDisplayContext()

	const { complete, incomplete, blank, filtered } = sortedLists

	return (
		<>
			{updateMember && !filtered ? (
				<>
					<div className='label-entry-list-header'>
						{blank.length} blank contacts
					</div>
					<BlankEntriesList blanks={blank} />
					<div className='label-entry-list-header'>
						{incomplete.length} incomplete contacts
					</div>
					<IncompleteEntriesList incompletes={incomplete} />
				</>
			) : null}
			<List id='member-list' verticalAlign='middle'>
				<MemberListMenu editVersion={!!updateMember} />
				{complete.length === 0 && emptyComponent}
				{filtered ? (
					<MemberListSection letter={[null, complete]} />
				) : (
					complete.map(letter => (
						<MemberListSection letter={letter} key={letter[0]} />
					))
				)}
			</List>
		</>
	)
}

export default MemberList
