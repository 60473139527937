import './ClubBanner.less'

import React from 'react'
import { Image } from 'semantic-ui-react'
import ClubBannerImage from './ClubBannerImage'

const ClubBanner = ({
	children,
	owner,
	clubId,
	hash,
	loading,
	size = 1000,
	isAdministrator = false
}) => {
	return (
		<div
			className={isAdministrator ? 'club-banner administrator' : 'club-banner'}
		>
			<ClubBannerImage
				className='club-banner-bg'
				owner={owner}
				clubId={clubId}
				size={size}
				hash={hash}
				loading={loading}
			/>
			<div className='club-banner-overlay'></div>
			<div className='club-banner-content'>{children}</div>
		</div>
	)
}

export default ClubBanner
