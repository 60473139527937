import 'firebase/functions'

import * as firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import {
	Button,
	Form,
	Icon,
	Input,
	Label,
	Loader,
	Segment
} from 'semantic-ui-react'

import { useMemberDisplayContext } from '../../../components/MemberDisplay/MemberDisplay'

const validEmail = e => {
	return e.includes('@') && !e.match(/\s+/)
}

const CreateFromEmails = () => {
	const [emails, setEmails] = useState([])
	const [input, setInput] = useState('')
	const inputRef = React.createRef()

	useEffect(() => {
		inputRef.current.focus()
	}, [])

	const { clubId } = useMemberDisplayContext()

	const addEmail = () => {
		const invalid = emails.includes(input) || !input.includes('@')

		setEmails(_emails => {
			if (invalid) return _emails
			return _emails.concat(input.split(/,\s?/).filter(i => validEmail(i)))
		})
		if (!invalid) setInput('')
		inputRef.current.focus()
	}

	const deleteEmail = email => () => {
		setEmails(_emails => _emails.filter(e => e != email))
	}

	const [loading, setLoading] = useState(false)

	const submitEmails = async () => {
		setLoading(true)
		try {
			const res = await firebase.functions().httpsCallable('createBulkMembers')(
				{
					club: clubId,
					members: emails.map(email => ({ email, card: null }))
				}
			)
			console.log(res)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	return (
		<div className='create-modal create-from-emails'>
			<h3>Create Contacts By Email</h3>
			<Segment className='emails-wrap'>
				{emails.map(email => (
					<Label key={email}>
						{email}
						<Icon name='delete' onClick={deleteEmail(email)} />
					</Label>
				))}
				{emails.length === 0 && (
					<p className='no-emails'>
						Type or paste a comma-separated list of emails below, then press
						enter or hit 'Add' to add them to the list.
					</p>
				)}
			</Segment>
			<Form onSubmit={addEmail}>
				<Form.Input
					style={{ margin: '0' }}
					fluid
					value={input}
					action
					placeholder='email@example.com, emailtwo@example.com, emailthree@example.com...'
					onChange={(e, { value }) => setInput(value)}
				>
					<input ref={inputRef} />
					<Button type='submit'>Add</Button>
				</Form.Input>
			</Form>
			<Button
				positive
				disabled={emails.length === 0 || loading}
				loading={loading}
				onClick={submitEmails}
				size='big'
			>
				Create {emails.length} Contact{emails.length != 1 && 's'}
			</Button>
		</div>
	)
}

export default CreateFromEmails
