import React, { useState, useEffect } from 'react'
import { clubBannerUrl } from '../util'
import { Image, Loader, Dimmer } from 'semantic-ui-react'

const ClubBannerImage = ({
	owner,
	clubId,
	size = 1000,
	hash,
	loading,
	...props
}) => {
	const DEFAULT_IMAGE = '/banner_default.jpg'

	let img = DEFAULT_IMAGE

	if (clubId && owner && size) img = clubBannerUrl(owner, clubId, size)

	const [internalLoading, setInternalLoading] = useState(true)

	return (
		<Dimmer.Dimmable {...props}>
			<Dimmer active={internalLoading || loading}>
				<Loader />
			</Dimmer>
			<img
				src={img + (hash ? `&hash=${hash}` : '')}
				width={size}
				height={size === 1000 ? 400 : size}
				onError={e => (e.target.src = DEFAULT_IMAGE)}
				onLoad={e => setInternalLoading(false)}
				onLoadStart={e => setInternalLoading(true)}
			/>
		</Dimmer.Dimmable>
	)
}

export default ClubBannerImage
