import React from 'react'
import { Header, Icon, Segment } from 'semantic-ui-react'

const NotFound404 = ({ match }) => {
	return (
		<Segment placeholder>
			<Header icon>
				<Icon name='question' />
				Page not found.
			</Header>
		</Segment>
	)
}

export default NotFound404
