import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

const NoClubFound = () => {
	const history = useHistory()

	// A newly created club will have `?creating=true` appended to the URL by the
	// success redirect from Stripe.
	const query = new URLSearchParams(history.location.search)
	const isCreating = query.get('creating') == 'true'

	if (isCreating)
		return (
			<Segment placeholder>
				<Header icon>
					<Icon name='check' color='green' />
					Your club is being created, please wait a moment!
				</Header>
				<Segment.Inline>
					<p>
						(If you aren't automatically brought to your new club soon, refresh
						the page.)
					</p>
				</Segment.Inline>
			</Segment>
		)

	return (
		<Segment placeholder>
			<Header icon>
				<Icon name='warning' />
				Wuh oh.
			</Header>
			<Segment.Inline>
				<p>
					This club either doesn&apos;t exist, or doesn&apos;t belong to you.
				</p>
			</Segment.Inline>
			<Button primary onClick={() => history.push('/app')}>
				Go to my clubs
			</Button>
		</Segment>
	)
}

export default NoClubFound
