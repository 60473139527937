import { promisify } from 'util'

import Chance from 'chance'
import firebase from 'firebase'
import Fuse from 'fuse.js'
import { parsePhoneNumber } from 'libphonenumber-js'
import React from 'react'
import { Message } from 'semantic-ui-react'
import VCard, { Property } from 'vcfer'

import { PROP_ORDER, standardTypes } from './vCardDefaults'
import vUtil from './vutil'

export * from './vCardDefaults'

const chance = new Chance()

export const MEDIA_QUERY_MOBILE = '(max-width: 767.98px)'

/**
 * @function
 * @template {any} T
 * @param {T[]} a The array being inserted into
 * @param {T} k The object being sorted into the array
 * @param {(a: T, b: T) => number} compare
 * a > b = 1, a == b = 0, a < b = -1
 * @returns {T[]}
 */
export function arrayInsertSort(a, k, compare) {
	if (!a || !Array.isArray(a)) {
		throw new Error('arrayInsertSort: not an array')
	}
	for (let i = 0; i < a.length; i++) {
		const match = compare(k, a[i])
		if (match < 1) {
			// 😂
			a.splice(i, 0, k)
			return a
		}
	}
	a.push(k)
	return a
}

/**
 * @function
 * @param {String} str
 */
export function toCapitalized(str) {
	if (!str) return undefined
	return String(str)
		.toLowerCase()
		.replace(/^\w/, c => c.toUpperCase())
}

/**
 * @typedef MemberItem
 * @property {string} name
 * @property {string} org
 * @property {string} error
 */
/**
 * @typedef SortedMemberItem
 * @property {string} name
 * @property {string} org
 * @property {string} error
 * @property {string} id
 */
/**
 * @typedef SortedLists
 * @property {SortedMemberItem[]} complete
 * @property {SortedMemberItem[]} incomplete
 * @property {SortedMemberItem[]} blank
 * @property {boolean} filtered
 */
export const initialSortedLists = {
	complete: [],
	incomplete: [],
	blank: []
}

/**
 * as defined in util/index.js, the maximum length a search can be.
 * set here, and here only. search input bars reflect this.
 */
export const MAX_FILTER_LENGTH = 32

/**
 * @function
 * @param {{[id: string] : MemberItem}} memberList
 * @param {string} filter
 * @returns {SortedLists}
 */
export const sortMemberLists = (memberList, filter) => {
	/** @type {{[letter: string] : SortedMemberItem[]}} */
	let complete = {}
	let incomplete = []
	let blank = []

	/** @param {SortedMemberItem} entry */
	const sortOneEntry = entry => {
		const { first, last, middle, prefix, suffix } = nameObj(entry.name)
		const org = entry.org
		const isComplete = first || last || org
		const isIncomplete = !first && !last && !org && entry.email
		const isBlank = !first && !last && !org && !entry.email

		if (isIncomplete) {
			incomplete.push(entry)
			return
		}
		if (isBlank) {
			blank.push(entry)
			return
		}

		let firstLetter = s =>
			s
				.trim()
				.substring(0, 1)
				.toUpperCase()
		const header = last
			? firstLetter(last)
			: first
			? firstLetter(first)
			: entry.org
			? firstLetter(entry.org)
			: '?'

		// cleanse "The" from the start of organization names.
		const cmprOrg = org => (org.match(/^the /i) ? org.substring(0, 3) : org)
		const cmpr = e => {
			const name = nameObj(e.name)
			return '' + name.last + name.first + name.middle + cmprOrg(e.org)
		}

		if (!Array.isArray(complete[header])) complete[header] = []

		arrayInsertSort(
			complete[header.match(/[^a-zA-z]/g) ? '#' : header],
			entry,
			(a, b) => cmpr(a).localeCompare(cmpr(b))
		)
	}

	// turn to array with id key in every object
	const mappedEntries = Object.entries(memberList).map(e => ({
		...e[1],
		id: e[0]
	}))

	// if there's no filter, don't even bother instantializing the FuzzySet
	if (filter && filter.length > 0) {
		const fuse = new Fuse(mappedEntries, {
			keys: ['name', 'org', 'email'],
			threshold: 0.4,
			shouldSort: true,
			maxPatternLength: MAX_FILTER_LENGTH
		})

		return {
			complete: fuse.search(filter),
			incomplete: null,
			blank: null,
			filtered: true
		}
	} else {
		mappedEntries.forEach(sortOneEntry)

		complete = Object.entries(complete).sort((a, b) => a[0].localeCompare(b[0]))

		return { complete, incomplete, blank, filtered: false }
	}
}

export const randomVCard = () => {
	const chances = {
		n: {
			firstName: 0.9,
			lastName: 0.75,
			middleName: 0.25,
			prefix: 0.125,
			suffix: 0.125
		},
		org: {
			count: 1,
			value: () => chance.company()
		},
		title: {
			count: 1,
			value: () => chance.profession()
		},
		email: {
			types: ['work', 'home'],
			count: 3,
			value: () => chance.email()
		},
		tel: {
			types: ['work', 'home'],
			count: 3,
			value: () => parsePhoneNumber(chance.phone(), 'US').getURI()
		},
		adr: {
			types: ['work', 'home'],
			count: 2,
			value: () =>
				[
					'',
					chance.address(),
					'',
					chance.city(),
					chance.state(),
					chance.zip(),
					chance.country({
						full: true
					})
				].join(';')
		}
	}

	const card = new VCard()

	// do for each in chances
	for (const k in chances) {
		const c = chances[k]
		if (k === 'n') {
			const hasFirst = Math.random() < c.firstName
			const hasLast = !hasFirst || Math.random() < c.lastName
			const hasMiddle = hasFirst && Math.random() < c.middleName
			const hasPrefix = Math.random() < c.prefix
			const hasSuffix = Math.random() < c.suffix
			const prefix = hasPrefix ? chance.prefix() : ''
			const first = hasFirst ? chance.first() : ''
			const middle = hasMiddle ? chance.first() : ''
			const last = hasLast ? chance.last() : ''
			const suffix = hasSuffix ? chance.suffix() : ''
			card.add('n', [last, first, middle, prefix, suffix].join(';'))
			// card.add('fn', fullName)
		} else {
			const count = Math.round(Math.random() * c.count)
			for (let i = 0; i < count; i++) {
				const type = c.types
					? c.types[Math.floor(Math.random() * c.types.length)]
					: undefined
				card.add(k, c.value(), {
					type
				})
			}
		}
	}
	return card
}

export const randomCard = () => {
	const hasFirst = Math.random() < 0.9
	const hasLast = Math.random() < 0.75
	const hasMiddle = hasFirst && Math.random() < 0.25
	const hasPrefix = Math.random() < 0.125
	const hasSuffix = Math.random() < 0.125
	const prefix = hasPrefix ? chance.prefix() : ''
	const first = hasFirst ? chance.first() : ''
	const middle = hasMiddle ? chance.first() : ''
	const last = hasLast ? chance.last() : ''
	const suffix = hasSuffix ? chance.suffix() : ''

	const nameArray = [last, first, middle, prefix, suffix]
	// console.log(nameArray);

	const hasOrg = Math.random() < 0.5

	return [
		'vcard',
		[
			['version', {}, 'text', '4.0'],
			['n', {}, 'text', nameArray],
			['org', {}, 'text', hasOrg ? chance.company() : ''],
			['title', {}, 'text', 'Shrimp Man'],
			[
				'photo',
				{ mediatype: 'image/gif' },
				'text',
				'http://www.example.com/dir_photos/my_photo.gif'
			],
			[
				'tel',
				{ type: ['work', 'voice'], value: 'uri' },
				'uri',
				'tel:+17135551212'
			],
			[
				'tel',
				{ type: ['home', 'voice'], value: 'uri' },
				'uri',
				'tel:+14045551212'
			],
			[
				'adr',
				{
					type: 'work',
					label:
						'"100 Waters Edge\\nBaytown, LA 30314\\nUnited States of America"'
				},
				'text',
				[
					'',
					'',
					'100 Waters Edge',
					'Baytown',
					'LA',
					'30314',
					'United States of America'
				]
			],
			[
				'adr',
				{
					type: 'home',
					label:
						'"42 Plantation St.\\nBaytown, LA 30314\\nUnited States ofAmerica"'
				},
				'text',
				[
					'',
					'',
					'42 Plantation St.',
					'Baytown',
					'LA',
					'30314',
					'United States of America'
				]
			],
			['email', {}, 'text', 'forrestgump@example.com'],
			['rev', {}, 'text', '20080424T195243Z']
		]
	]
}

export const randomCompany = () => {
	const company = chance.company()

	return [
		'vcard',
		[
			['version', {}, 'text', '4.0'],
			['n', {}, 'text', ['', '', '', '', '']],
			['fn', {}, 'text', ''],
			['org', {}, 'text', company],
			[
				'tel',
				{ type: ['work', 'voice'], value: 'uri' },
				'uri',
				'tel:+11115551212'
			],
			[
				'tel',
				{ type: ['home', 'voice'], value: 'uri' },
				'uri',
				'tel:+14045551212'
			],
			[
				'adr',
				{
					type: 'work',
					label:
						'"100 Waters Edge\\nBaytown, LA 30314\\nUnited States of America"'
				},
				'text',
				[
					'',
					'',
					'100 Waters Edge',
					'Baytown',
					'LA',
					'30314',
					'United States of America'
				]
			],
			[
				'adr',
				{
					type: 'home',
					label:
						'"42 Plantation St.\\nBaytown, LA 30314\\nUnited States ofAmerica"'
				},
				'text',
				[
					'',
					'',
					'42 Plantation St.',
					'Baytown',
					'LA',
					'30314',
					'United States of America'
				]
			],
			['email', {}, 'text', 'forrestgump@example.com'],
			['rev', {}, 'text', '20080424T195243Z']
		]
	]
}

/**
 *
 * @param {function[]} functions
 * @param {...any} args arguments
 */
export const randomlyDo = (functions, ...args) => {
	const select = Math.floor(Math.random() * functions.length)
	return functions[select](...args)
}

/** sleep for a number in milliseconds. */
export const sleep = delay => new Promise(r => setTimeout(r, delay))

/**
 * @param {string} name
 */
export const nameObj = name => {
	const n = name.split(';')
	return {
		prefix: n[3],
		first: n[1],
		middle: n[2],
		last: n[0],
		suffix: n[4]
	}
}

export const isOrgSortMap = sortMap => {
	return sortMap.org !== '' && sortMap.name.split(';').every(n => n === '')
}

/**
 * Returns an array of names sorted in the order specified by
 * PROP_ORDER.
 * @param {string[]} names
 * @returns {string[]}
 */
export const sortedPropNames = _names => {
	let names = Array.from(_names)
	names.sort((a, b) => {
		const ia = PROP_ORDER.indexOf(a)
		const ib = PROP_ORDER.indexOf(b)
		return ia - ib
	})
	return names
}

// TODO fix Properties without any label at all

/**
 * Makes an educated guess at what the label is for a property.
 * Returns the index, and the value in lowercase.
 * @param {import('vcfer').Property} prop
 * @param {string[]} incl
 * @param {string[]} excl
 * @returns {[number, string, number]} `[labelIndex, label, inclIndex]`
 */

// TODO deprecated
export const getPropTypeLabel = (prop, incl, excl) => {
	return null

	// if (!prop || !prop.params.type) return [-1, null, -1]
	// /** @type {string[]} */
	// let types = Array.isArray(prop.params.type)
	// 	? prop.params.type.slice(0)
	// 	: [prop.params.type]

	// /** @type {{ incl: string[], excl: string[]}} */
	// // let { incl, excl } = standardTypes[prop.getField()]

	// if (!excl) excl = []
	// excl.push('pref')

	// types = types.filter(type => !excl.includes(type))

	// if (!incl) return [0, types[0].toLowerCase(), -1]

	// if (types.length == 0) return [-1, null, -1]
	// if (types.length > 1) {
	// 	for (const [i, inc] in incl.entries()) {
	// 		if (types.includes(inc)) return [prop.type.indexOf(inc), inc, i]
	// 	}
	// }

	// return [
	// 	prop.params.type.indexOf(types[0]),
	// 	types[0].toLowerCase(),
	// 	incl.indexOf(types[0])
	// ]
}

/**
 *
 * @param {string} owner
 * @param {string} club
 * @param {string} member
 * @param {500 | 250 | 50 | 25} size
 */
export const memberImageUrl = (owner, club, member, size = 500) => {
	if (!owner || !club || !member || !size) return '/avatar.jpg'

	return (
		`https://firebasestorage.googleapis.com/v0/b/${
			firebase.app().options.storageBucket
		}/o/${owner}` +
		`%2Fclubs%2F${club}%2Fmembers%2F${member}_${size}x${size}.jpg?alt=media`
	)
}

/**
 *
 * @param {string} owner
 * @param {club} club
 * @param {1000 | 500 | 250 | 50 | 25} size
 */
export const clubBannerUrl = (owner, club, size = 1000) => {
	return (
		`https://firebasestorage.googleapis.com/v0/b/${
			firebase.app().options.storageBucket
		}/o/${owner}` +
		`%2Fclubs%2F${club}%2Fbanner%2Fbanner_${size}x${
			size === 1000 ? 400 : size // 1000x400, all else square
		}.jpg?alt=media`
	)
}

/**
 *
 * @param {File} file
 * @returns {Promise<string>} Base64 data
 */
export const fileToBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = e => resolve(e.target.result)
		try {
			reader.readAsDataURL(file)
		} catch (err) {
			reject(err)
		}
	})
}

// export const stripe = window.Stripe(apiKey)

export const fuzzySortComplete = complete => {}

// vCards use a number of reserved characters: ,;:='"
// these functions escape them with a backslash for displaying
// and saving
export const escapeText = text => {
	if (!text) return text
	return (text + '')
		.replace(/([,;:='"])/gm, '\\$1')
		.replace(/([\n])/gm, '\\n')
		.replace(/([\t])/gm, '\\t')
}
export const unescapeText = text => {
	if (!text) return text
	return (text + '')
		.replace(/\\n/gm, '\n')
		.replace(/\\t/gm, '\t')
		.replace(/\\([,;:='"])/gm, '$1')
}
