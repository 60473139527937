import React, { useState } from 'react'
import { Icon, Image } from 'semantic-ui-react'

import { memberImageUrl } from '../util'

/**
 *
 * @param {object} props
 * @param {string} props.owner the owner
 * @param {string} props.clubId the club's Id
 * @param {string} props.memberId the member's id
 * @param {500|250|50|25} props.thumbSize the thumbnail size to retrieve from server
 * @param {number} props.size the display size
 * @param {boolean} props.hideOnError render null if error
 */
const MemberImage = ({
	owner,
	clubId,
	memberId,
	thumbSize,
	size = 100,
	hideOnError = false,
	iconOnFail = false
}) => {
	const img = memberImageUrl(owner, clubId, memberId, thumbSize)

	const [error, setError] = useState(false)

	if (error) {
		if (hideOnError) return null
		if (iconOnFail)
			return (
				<Image>
					<Icon circular fitted name={iconOnFail || 'user'} />
				</Image>
			)
	}

	return (
		<Image
			src={img}
			circular
			width={size}
			height={size}
			onError={e => {
				setError(true)
				e.target.src = '/avatar.jpg'
			}} // TODO better avatar
			style={{
				width: size + 'px',
				height: size + 'px'
			}}
		/>
	)
}

export default MemberImage
