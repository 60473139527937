import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'

const TermsOfUse = () => {
	return (
		<Container text>
			<Segment raised>
				<Header>Terms of Use</Header>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec
					ultrices tincidunt arcu non sodales neque. Quis ipsum suspendisse
					ultrices gravida dictum fusce. Turpis massa tincidunt dui ut ornare
					lectus sit amet. Nunc non blandit massa enim nec. Diam vulputate ut
					pharetra sit amet aliquam id diam maecenas. Orci a scelerisque purus
					semper eget duis at tellus. Ligula ullamcorper malesuada proin libero
					nunc. Adipiscing diam donec adipiscing tristique risus nec feugiat in.
					Vitae auctor eu augue ut. Sagittis id consectetur purus ut faucibus
					pulvinar elementum. Risus commodo viverra maecenas accumsan lacus vel
					facilisis volutpat est. Est pellentesque elit ullamcorper dignissim
					cras tincidunt lobortis. Ultrices neque ornare aenean euismod
					elementum nisi quis eleifend.
				</p>

				<p>
					Commodo quis imperdiet massa tincidunt. Lobortis feugiat vivamus at
					augue eget arcu dictum. Congue nisi vitae suscipit tellus mauris a
					diam maecenas sed. Nisl condimentum id venenatis a condimentum.
					Quisque non tellus orci ac auctor. Iaculis eu non diam phasellus
					vestibulum lorem sed. Amet luctus venenatis lectus magna fringilla
					urna. Tincidunt id aliquet risus feugiat. Diam maecenas ultricies mi
					eget. Volutpat odio facilisis mauris sit amet massa vitae tortor.
					Tellus pellentesque eu tincidunt tortor. Quis viverra nibh cras
					pulvinar mattis. In est ante in nibh. Massa sapien faucibus et
					molestie ac feugiat sed lectus vestibulum. Nulla at volutpat diam ut
					venenatis tellus in metus. Nunc sed velit dignissim sodales ut.
					Blandit cursus risus at ultrices mi tempus imperdiet.
				</p>

				<p>
					Pellentesque nec nam aliquam sem et tortor consequat id porta. A
					scelerisque purus semper eget duis. Aliquam malesuada bibendum arcu
					vitae elementum curabitur. Ac turpis egestas maecenas pharetra
					convallis posuere morbi leo urna. Consequat mauris nunc congue nisi
					vitae suscipit tellus. In nisl nisi scelerisque eu ultrices. Pulvinar
					etiam non quam lacus suspendisse faucibus interdum posuere lorem.
					Egestas diam in arcu cursus euismod quis viverra. Mi eget mauris
					pharetra et. Integer feugiat scelerisque varius morbi enim nunc
					faucibus a. Consequat nisl vel pretium lectus quam id leo. Sit amet
					commodo nulla facilisi nullam vehicula. Diam maecenas sed enim ut sem
					viverra aliquet. Lectus urna duis convallis convallis tellus id. Nulla
					posuere sollicitudin aliquam ultrices sagittis. Nec nam aliquam sem et
					tortor consequat id porta nibh. Id ornare arcu odio ut sem nulla.
				</p>

				<p>
					Duis ultricies lacus sed turpis tincidunt. Aliquet nibh praesent
					tristique magna. Metus aliquam eleifend mi in nulla. Aliquam etiam
					erat velit scelerisque in dictum. Tellus elementum sagittis vitae et
					leo duis ut diam quam. Scelerisque viverra mauris in aliquam sem
					fringilla ut. Lorem ipsum dolor sit amet. Magna eget est lorem ipsum
					dolor sit amet consectetur. Mauris augue neque gravida in fermentum et
					sollicitudin ac. Interdum posuere lorem ipsum dolor sit. Nulla
					pellentesque dignissim enim sit amet. Tristique sollicitudin nibh sit
					amet commodo. Et odio pellentesque diam volutpat. Neque volutpat ac
					tincidunt vitae semper quis lectus nulla.
				</p>

				<p>
					Morbi tristique senectus et netus et malesuada. Scelerisque eu
					ultrices vitae auctor. Habitasse platea dictumst vestibulum rhoncus.
					Molestie a iaculis at erat pellentesque adipiscing commodo elit at. A
					diam maecenas sed enim ut. Nunc sed augue lacus viverra vitae. Vel
					facilisis volutpat est velit egestas. Turpis cursus in hac habitasse
					platea. Euismod elementum nisi quis eleifend quam. Sit amet facilisis
					magna etiam tempor orci eu lobortis elementum. Nec ultrices dui sapien
					eget mi proin sed libero. Accumsan sit amet nulla facilisi morbi. Ante
					metus dictum at tempor commodo ullamcorper a lacus. Sed vulputate odio
					ut enim. Eget gravida cum sociis natoque. Diam volutpat commodo sed
					egestas egestas. Eu augue ut lectus arcu bibendum. Ut sem nulla
					pharetra diam sit. Diam vel quam elementum pulvinar etiam.
				</p>
			</Segment>
		</Container>
	)
}

export default TermsOfUse
