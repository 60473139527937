import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Button, Header, Icon, Menu, Modal, Popup } from 'semantic-ui-react'

import { useEditMemberContext } from './EditMember'

const EditMemberMenu = ({
	handleSaveButton,
	editLink,
	toggleEmailSection,
	deleteMember,
	editorVersion
}) => {
	const { clubId, member, hasRequiredError, canSave } = useEditMemberContext()

	const handleEditLink = () => {
		window.open(editLink, '_blank')
	}

	const [copied, setCopied] = useState(false)

	const handleCopy = () => {
		copy(window.location.origin + editLink)

		setCopied(true)
	}

	const [showingDelete, setShowingDelete] = useState(false)

	const [deleting, setDeleting] = useState(false)

	const handleDelete = async () => {
		setDeleting(true)
		await deleteMember()
		setDeleting(false)
		setShowingDelete(false)
	}

	const openPublicVersion = () => {
		window.open(
			window.location.origin + '/c/' + clubId + '/' + member.id,
			'_blank'
		)
	}

	return (
		<Menu className='edit-member-menu'>
			<Menu.Menu position='left'>
				{toggleEmailSection ? (
					<Popup
						inverted
						position='bottom center'
						on={['hover', 'focus']}
						trigger={
							<Menu.Item onClick={toggleEmailSection} icon='envelope outline' />
						}
						content='Edit Associated Email'
					/>
				) : null}
				{editLink && !editorVersion ? (
					<Popup
						inverted
						position='bottom center'
						on={['hover', 'focus']}
						trigger={
							<Menu.Item
								link
								onClick={handleCopy}
								onMouseOver={() => setCopied(false)}
								icon='chain'
							/>
						}
						content={copied ? 'Copied!' : 'Copy Edit Link'}
					/>
				) : null}

				<Popup
					inverted
					position='bottom center'
					on={['hover', 'focus']}
					trigger={<Menu.Item onClick={openPublicVersion} icon='user circle' />}
					content='Open Public Version'
				/>
			</Menu.Menu>
			<Menu.Menu position='right'>
				{!editorVersion ? (
					<Modal
						trigger={
							<Popup
								inverted
								position='bottom center'
								on={['hover', 'focus']}
								trigger={
									<Menu.Item
										link
										icon='trash'
										onClick={() => setShowingDelete(true)}
									/>
								}
								content='Delete Contact'
							/>
						}
						basic
						// size='small'
						open={showingDelete || deleting}
						onClose={() => setShowingDelete(false)}
					>
						<Header icon='warning' content='Confirm Delete Contact' />
						<Modal.Content>
							<p>Confirm deleting this contact.</p>
						</Modal.Content>
						<Modal.Actions>
							<Button
								basic
								color='red'
								icon='remove'
								content='No'
								onClick={() => setShowingDelete(false)}
							/>
							<Button
								basic
								color='green'
								icon='checkmark'
								content='Yes'
								loading={deleting}
								onClick={handleDelete}
							/>
						</Modal.Actions>
					</Modal>
				) : null}
				<Menu.Item
					onClick={handleSaveButton}
					className='save-button'
					disabled={!canSave()}
				>
					Save
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

export default EditMemberMenu
