import './Brand.less'

import React, { useState } from 'react'
import { Transition } from 'semantic-ui-react'

const Brand = ({ color }) => {
	const style = !!color ? { color } : undefined

	const [visible, setVisible] = useState(true)

	const toggleVisible = () => setVisible(v => !v)

	return (
		<div className='brand' onMouseEnter={toggleVisible}>
			<div style={style} className='brand-name'>
				Circlus
			</div>
			<Transition animation='jiggle' duration={100} visible={visible}>
				<div style={style} className='beta-tag'>
					BETA
				</div>
			</Transition>
		</div>
	)
}

export default Brand
