import React from 'react'
import { Placeholder } from 'semantic-ui-react'

const ClubLoadingSkeleton = () => {
	return (
		<Placeholder style={{ height: 400, width: 1000 }}>
			<Placeholder.Image />
		</Placeholder>
	)
}

export default ClubLoadingSkeleton
