import * as firebase from 'firebase/app'

export default () => {
	// const app = firebase.initializeApp({
	// 	apiKey: 'AIzaSyB4c81jJR_bM8J5_euR1JEbpwAcWCass8s',
	// 	authDomain: 'circlus-development.firebaseapp.com',
	// 	databaseURL: 'https://circlus-development.firebaseio.com',
	// 	projectId: 'circlus-development',
	// 	storageBucket: 'circlus-development.appspot.com',
	// 	messagingSenderId: '1021853797883',
	// 	appId: '1:1021853797883:web:acc791ca58abef9cbe0f5e',
	// 	measurementId: 'G-97C7MKMVWL'
	// })

	console.log(process.env.REACT_APP_FIREBASE_CONFIG)
	const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)

	console.log(firebaseConfig)

	const app = firebase.initializeApp(
		JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
	)

	if (process.env.NODE_ENV === 'development')
		app.functions().useFunctionsEmulator('http://localhost:5001')

	console.log(app.functions()._url('ping'))
	console.log(app.firestore())
}
