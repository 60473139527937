import './SegmentCloser.less'

import React from 'react'
import { Icon } from 'semantic-ui-react'

const SegmentCloser = ({ onClick }) => {
	return (
		<div className='segment-closer' onClick={onClick}>
			<Icon name='x' />
		</div>
	)
}

export default SegmentCloser
