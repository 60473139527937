import 'firebase/functions'

import './CreateNewClub.less'

import * as firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import {
	Button,
	Dimmer,
	Divider,
	Form,
	Header,
	Input,
	Loader,
	Message,
	Segment
} from 'semantic-ui-react'

const CreateNewClub = () => {
	return (
		<div className={isMobile ? 'new-club-page-mobile' : 'new-club-page'}>
			<CreateNewClubForm />
		</div>
	)
}

const InputWrap = props => {
	const { state } = props
	const handleChange = e => {
		state[1](e.target.value)
	}
	return <Form.Input value={state[0]} onChange={handleChange} {...props} />
}

const CreateNewClubForm = props => {
	const [creating, setCreating] = useState(false)
	const [creatingMessage, setCreatingMessage] = useState('')
	const [successRedirect, setSuccessRedirect] = useState(null)

	const [error, setError] = useState(null)

	const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

	const newClub = async () => {
		if (creating) return false
		setCreating(true)
		try {
			const res = await firebase.functions().httpsCallable('payForClub')({
				name: name.trim()
			})
			console.log(res)
			setCreatingMessage('Redirecting to payment...')

			await stripe.redirectToCheckout({ sessionId: res.data.sessionId })
		} catch (err) {
			setCreating(false)
			setError(err.message)
			console.error(err)
		}
	}

	const [name, setName] = useState('')

	return (
		<Segment
			padded={isMobile ? false : 'very'}
			raised={!isMobile}
			vertical={isMobile}
			className='form-segment'
		>
			<Dimmer active={creating} inverted>
				<Loader>{creatingMessage}</Loader>
			</Dimmer>
			{error ? (
				<Message error>
					<Message.Header>Error!</Message.Header>
					<Message.Content>
						There was an error while creating your club. Make sure:
					</Message.Content>
					<Message.List>
						<li>...you entered a name for your club.</li>
						<li>
							...your club&apos;s name is between 2 and 50 characters long.
						</li>
						<li>...you are connected to the internet.</li>
					</Message.List>
				</Message>
			) : null}
			<Form onSubmit={newClub}>
				<Divider horizontal>
					<Header as='h4'>Information</Header>
				</Divider>

				<InputWrap placeholder='Name' name='name' state={[name, setName]} />

				<Divider horizontal>
					<Header as='h4'>Payment</Header>
				</Divider>

				<Form.Button className='submit' content='Create' size='huge' />
			</Form>
		</Segment>
	)
}

export default CreateNewClub
