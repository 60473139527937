import 'firebase/functions'

import * as firebase from 'firebase/app'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-semantic-toasts'
import { Button, Checkbox, Message, Segment } from 'semantic-ui-react'

const ConfirmDelete = ({ clubReference, clubData, clubId }) => {
	const { deleteKey } = useParams()

	const [deleting, setDeleting] = useState(false)
	const [checked, setChecked] = useState(false)
	const [error, setError] = useState(false)

	const history = useHistory()

	const handleClick = async e => {
		if (!checked) return
		try {
			setDeleting(true)
			await firebase.functions().httpsCallable('deleteClubFromKey')({
				club: clubId,
				deleteKey
			})

			history.push('/app')
		} catch (err) {
			setDeleting(false)
			setError(true)
			console.error(err)
		}
	}

	return (
		<div className='confirm-delete-wrap'>
			<div className='confirm-delete'>
				<Segment raised>
					<Checkbox
						checked={checked}
						onChange={() => setChecked(!checked)}
						label={`Permanently delete my club '${clubData.name}'. This cannot be undone.`}
					/>
					{error && (
						<Message
							error
							content='There was an error when trying to delete your club.'
						/>
					)}
					<Button
						disabled={!checked || deleting}
						loading={deleting}
						negative
						onClick={handleClick}
					>
						Delete Club
					</Button>
				</Segment>
			</div>
		</div>
	)
}

export default ConfirmDelete
