import 'firebase/firestore'

import * as firebase from 'firebase/app'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	useCollectionData,
	useDocumentData
} from 'react-firebase-hooks/firestore'

import { useAuthContext } from './AuthHook'

/** @type {React.Context<IAppContext>} */
export const AppContext = React.createContext()
export const useAppContext = () => React.useContext(AppContext)
export const AppProvider = ({ children }) => {
	const { user } = useAuthContext()

	const [userDoc, loading, error] = useDocumentData(
		firebase
			.firestore()
			.collection('users')
			.doc(user.uid),
		{
			idField: 'uid',
			snapshotListenOptions: {
				includeMetadataChanges: false
			}
		}
	)

	const clubNameForId = id => {
		if (!userDoc) return null
		return userDoc.ownedClubs[id]
	}

	return (
		<AppContext.Provider
			value={{
				userDoc,
				loading,
				error,
				clubNameForId
			}}
		>
			{children}
		</AppContext.Provider>
	)
}
