import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { Property } from 'vcfer'

import { toCapitalized } from '../../util'
import { useEditMemberContext } from './EditMember'
import { usePropHandlerContext } from './PropHandler'

/**
 * make an educated guess at what the label probably is.
 *
 * Returns the index, the value in lowercase, and death!!!
 *
 * @param {Property} prop
 * @param {string[][]} incl
 * @param {string[]} exclude
 * 
 * @returns {{
    index: number,
    val: string,
    valLabel: string,
    isCustom: boolean
  }}
 */
const getPropTypeLabel = (prop, incl, exclude) => {
	if (!prop || !prop.params.type)
		return {
			index: -1,
			val: null,
			isCustom: true
		}

	let types = Array.isArray(prop.params.type)
		? prop.params.type.slice(0)
		: [prop.params.type]

	let type = Array.isArray(prop.params.type)
		? prop.params.type[0]
		: prop.params.type

	if (!incl) {
		return {
			index: -1
		}
	}

	// let excl = exclude
	// if (!excl) excl = []
	// excl.push('pref')
	// types = types.filter(type => !excl.includes(type))

	if (!incl)
		return {
			index: -1,
			val: types[0].toLowerCase(),
			valLabel: toCapitalized(types[0]),
			isCustom: true
		}

	if (types.length == 0)
		return {
			index: -1,
			val: null,
			valLabel: null,
			isCustom: true
		}

	if (types.length > 1) {
		for (const [i, val] of incl.entries()) {
			const [inc, display] = val
			if (types.includes(inc))
				return {
					index: prop.params.type.indexOf(inc),
					val: inc,
					valLabel: display,
					isCustom: false
				}
		}
	}

	return {
		index: prop.params.type.indexOf(types[0]),
		val: types[0].toLowerCase(),
		valLabel: toCapitalized(types[0]),
		isCustom: true
	}
}

/** @type {React.FC<{ prop: Property}>} */
const TypePicker = ({ prop }) => {
	const { updateEditMember } = useEditMemberContext()

	const {
		pickerTypesExclude,
		pickerTypes,
		pickerModifiesSemicolonIndex
	} = usePropHandlerContext()

	const isExcluded = t => {
		if (!pickerTypesExclude) return false
		return pickerTypesExclude.includes(t)
	}

	const type = prop.params.type
		? (Array.isArray(prop.params.type)
			? prop.params.type.filter(t => !isExcluded(t))[0]
			: prop.params.type)
		: null

	const isCustom = !pickerTypes.find(pt => pt[0] === type)

	const [options, setOptions] = useState(() => {
		const opts = pickerTypes.map(type => ({
			key: type[0],
			text: type[1],
			value: type[0]
		}))
		if (isCustom && type)
			return [
				{
					key: type.toLowerCase(),
					text: toCapitalized(type),
					value: type.toLowerCase()
				},
				...opts
			]
		return opts
	})

	const pmsi =
		pickerModifiesSemicolonIndex || pickerModifiesSemicolonIndex === 0

	const [currentValue, setCurrentValue] = useState(() => {
		if (!type && !pmsi) {
			prop.params.type = pickerTypes[0][0]
			return prop.params.type
		} else if (pmsi) {
			return prop.value.split(';')[pickerModifiesSemicolonIndex]
		} else {
			return type
		}
	})

	const handleChange = (e, { value }) => {
		setCurrentValue(value)
		if (pmsi) {
			const v = prop.value.split(';')
			v[pickerModifiesSemicolonIndex] = value
			prop.value = v.join(';')
		} else {
			prop.params.type = value.toLowerCase()
		}
		// console.log(options)
		updateEditMember()
	}

	const handleAddItem = (e, { value }) => {
		const newItem = {
			key: value.toLowerCase(),
			text: toCapitalized(value),
			value: value.toLowerCase()
		}

		// no adding duplicate items
		if (options.filter(o => o.key == newItem.key).length === 0)
			setOptions([newItem, ...options])
		setCurrentValue(value.toLowerCase())
		e.target.blur()
	}

	return (
		<Dropdown
			className='type-picker'
			placeholder='Label'
			allowAdditions
			search
			selection
			options={options}
			value={currentValue}
			onChange={handleChange}
			onAddItem={handleAddItem}
		/>
	)
}

export default TypePicker
