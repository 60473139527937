import VCard, { Property } from 'vcfer'

import { PROP_ORDER, nameObj } from '.'

/**
 *
 * @param {VCard} card
 * @param {string} key
 * @returns {Property|undefined}
 */
const getPreferred = (card, key) => {
	let prop = card.get(key, 'pref')
	if (!prop[0]) prop = card.get(key)
	return prop[0] || undefined
}

/**
 * VCard utilities
 * @param {VCard} card
 */
export default card => ({
	/**
	 * Gets the `Property` with the key `'pref'`, if there
	 * are multiple properties.
	 * @param {string} key
	 * @returns {Property}
	 */
	getPreferred: key => getPreferred(card, key),
	/**
	 * Returns a formatted title for a card.
	 * @returns {string}
	 * */
	title: () => {
		const name = card.has('n') ? card.getOne('n').value : ''
		const fullName = card.has('fn')
			? card.getOne('fn').value
			: card.has('n')
			? card.parseFullName({ set: false }).value
			: ''
		const org = card.has('org') ? card.getOne('org').value : ''

		if (fullName !== '') return fullName

		if (org !== '')
			return org
				.split(';')
				.filter(i => !!i)
				.join(', ')
	},
	/**
	 * Returns true if a card only has an org, and no name.
	 * @returns {boolean}
	 */
	isOrg: () => {
		return (
			getPreferred(card, 'org') &&
			!(getPreferred(card, 'fn') ? getPreferred(card, 'fn').value : false) &&
			(getPreferred(card, 'n')
				? getPreferred(card, 'n')
						.value.split(';')
						.every(n => !n)
				: true)
		)
	},
	/**
	 * Returns true if the card has a name, full name, or org;
	 * returns false otherwise.
	 * @returns {boolean}
	 */
	isValid: () => {
		const name = card.get('n')[0]
		if (!name) return false
		const splitName = name.value.split(';')
		const org = card.get('org')[0]
		return !!splitName[0] || !!splitName[1] || (org && org.value != '')
	},
	/**
	 * Returns an array of all properties on a VCard
	 * @returns {Property[]}
	 */
	allProps: () => {
		if (!card) return
		let props = []
		for (let k in card.props) {
			const j = card.props.get(k)
			Array.isArray(j) ? props.concat(j) : props.push(j)
		}
		return props
	},
	/**
	 * @param {VCard} b
	 */
	compare: b => {
		const cmprOrg = org => (org.match(/^the /i) ? org.substring(0, 3) : org)
		/** @param {VCard} e */
		const cmpr = e => {
			const name = e.has('n') ? nameObj(e.getOne('n').value) : nameObj('')
			const org = e.has('org') ? e.getOne('org').value : ''
			return name
				? '' + name.last + name.first + name.middle + cmprOrg(org)
				: cmprOrg(org)
		}

		return cmpr(card).localeCompare(cmpr(b))
	}
})
