import './About.less'

import React from 'react'

import FullPagePadder from '../../components/FullPagePadder'

const About = () => {
	return (
		<FullPagePadder subtractMenu subtractFooter>
			<div className='about-john'>
				Circlus is developed by <a href='http://mcpar.land/'>John McParland</a>{' '}
				and Doug McMurrey,
				<br />
				in Houston, Texas.
			</div>
		</FullPagePadder>
	)
}

export default About
