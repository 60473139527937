import Papa from 'papaparse'
import VCard from 'vcfer'

/**
 * @typedef CsvRecord
 * @property {string} FIRST
 * @property {string} LAST
 * @property {string} ORG
 * @property {string} MIDDLE
 * @property {string=} ASSOCIATED_EMAIL the assoc
 * @property {string=} EMAIL
 * @property {string=} PHONE
 * @property {string=} ADDRESS
 * @property {string=} NICKNAME
 * @property {string=} URL
 * @property {string=} TITLE
 * @property {string=} DEPARTMENT
 * @property {string=} NOTE
 * @property {string=} GENDER
 * @property {string=} PREFIX
 * @property {string=} SUFFIX
 */

/**
 *
 * @param {CsvRecord} csv the CSV record to create from
 * @returns {{email: string, card: VCard}}
 */
const createFromCsv = csv => {
	if (!csv.LAST && !csv.FIRST && !csv.ORG)
		throw new Error(
			`value 'LAST', 'FIRST', or 'ORG' must be present. Got:` +
				`${csv.LAST}, ${csv.FIRST}, ${csv.ORG} from ${JSON.stringify(csv)}`
		)

	const card = new VCard()
	const res = { email: '', card: null }

	if (csv.LAST || csv.FIRST)
		card.set(
			'n',
			[csv.LAST, csv.FIRST, csv.MIDDLE, csv.PREFIX, csv.SUFFIX].join(';')
		)

	if (csv.ORG || csv.DEPARTMENT)
		card.set(
			'org',
			csv.DEPARTMENT ? [csv.ORG, csv.DEPARTMENT].join(';') : csv.ORG
		)

	res.email = csv.ASSOCIATED_EMAIL || ''

	if (csv.EMAIL) card.set('email', csv.EMAIL)

	if (csv.PHONE) card.set('tel', csv.PHONE)

	if (csv.ADDRESS) card.set('adr', csv.ADDRESS.replace('\n', ';'))

	if (csv.TITLE) card.set('title', csv.TITLE)

	if (csv.NICKNAME) card.set('nickname', csv.NICKNAME)

	if (csv.URL) card.set('url', csv.URL)

	if (csv.NOTE) card.set('note', csv.NOTE)

	if (csv.GENDER) card.set('gender', csv.GENDER)

	card.parseFullName({
		set: true
	})

	res.card = card
	return res
}

/**
 *
 * @param {string | File | NodeJS.ReadableStream} csvString
 * @returns {{email: string, card: VCard, error?: string}[]}
 */
export const parseCardsFromCsv = csvString => {
	const res = Papa.parse(csvString, {
		header: true
	})
	return res.data.map(record => {
		try {
			return { ...createFromCsv(record), error: null }
		} catch (err) {
			return {
				email: null,
				card: null,
				error: err.message
			}
		}
	})
}

export default createFromCsv
