import './AddButton.less'

import React from 'react'
import { Icon } from 'semantic-ui-react'

const AddButton = ({ onClick, content, icon, color, iconColor, className }) => {
	return (
		<div className='add-button-wrap'>
			<div className={'add-button ' + className} onClick={onClick}>
				<Icon
					name={icon}
					size='large'
					style={color || iconColor ? { color: iconColor || color } : null}
				/>
				<span style={color ? { color } : null}>{content}</span>
			</div>
		</div>
	)
}

export default AddButton
