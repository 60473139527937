import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Property } from 'vcfer'

import { toCapitalized } from '../../util'
import vutil from '../../util/vutil'
import { useViewMemberContext } from './ViewMember'

/** @type {React.FC<{
    icon: string,
    onClick: (prop: Property) => void,
    propName: string,
    propTypesIgnore: string[]
  }>} */
const QuickIcon = ({ icon, onClick, propName, propTypesIgnore }) => {
	const { card } = useViewMemberContext()

	const prop = vutil(card).getPreferred(propName)

	if (!prop) return null

	const subtitle = Array.isArray(prop.params['type'])
		? (propTypesIgnore
			? prop.params['type'].filter(e => !propTypesIgnore.includes(e))[0]
			: prop.params['type'][0])
		: prop.params['type']

	return (
		<div className='quick-icon' onClick={() => onClick(prop)}>
			<Icon.Group size='huge'>
				<Icon name='circle' />
				<Icon inverted name={icon} size='tiny' />
			</Icon.Group>
			<p>{subtitle ? toCapitalized(subtitle) : null}</p>
		</div>
	)
}

export default QuickIcon
