import './Address.less'

import React from 'react'
import { Input } from 'semantic-ui-react'

import { useEditMemberContext } from '../EditMember'

const AdrFieldInput = ({ prop, placeholder, semicolonIndex }) => {
	const { updateEditMember } = useEditMemberContext()

	const handleChange = (e, { value }) => {
		const v = prop.value.split(';')
		v[semicolonIndex] = value
		prop.value = v.join(';')
		updateEditMember()
	}

	return (
		<Input
			size='small'
			fluid
			onChange={handleChange}
			placeholder={placeholder}
			value={prop.value.split(';')[semicolonIndex]}
		>
			<input autoComplete='off' />
		</Input>
	)
}

const Address = ({ prop, typePicker }) => {
	return (
		<div className='prop-adr'>
			<div>
				<Input size='small' className='type-picker-wrap'>
					{typePicker}
				</Input>
			</div>
			<div className='input-group'>
				<AdrFieldInput
					prop={prop}
					placeholder='Street Address 1'
					semicolonIndex={1}
				/>
				<AdrFieldInput
					prop={prop}
					placeholder='Street Address 2'
					semicolonIndex={2}
				/>
				<div className='input-group-horizontal'>
					<AdrFieldInput prop={prop} placeholder='City' semicolonIndex={3} />
					<AdrFieldInput prop={prop} placeholder='State' semicolonIndex={4} />
				</div>
				<AdrFieldInput prop={prop} placeholder='Zip Code' semicolonIndex={5} />
			</div>
		</div>
	)
}

export default Address
