import './Login.less'

import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'

import LoginPanel from '../components/LoginPanel'
import { useAuthContext } from '../hooks/AuthHook'

const Login = ({ from, register = false }) => {
	const { user } = useAuthContext()
	const redirectTo = from || { pathname: 'app' }
	if (!!user) return <Redirect to={redirectTo} />
	return (
		<div id='login-page'>
			<LoginPanel register={register} />
		</div>
	)
}

export default Login
