import './MemberList.less'

import React from 'react'
import { Icon, Image, Label, List } from 'semantic-ui-react'

import { escapeText, isOrgSortMap, nameObj, unescapeText } from '../../util'
import vUtil from '../../util/vutil'
import MemberImage from '../MemberImage'
import { useMemberDisplayContext } from './MemberDisplay'

/**
 * @typedef MemberItem
 * @property {string} id
 * @property {string} name
 * @property {string} org
 * @property {string=} error
 * @property {string=} email
 */

/**
 *
 * @param {object} props
 * @param {MemberItem} props.member
 * @param {string} props.key
 */
const MemberListItem = ({ member, detailed }) => {
	const {
		onSelectMember,
		selectedMember,
		clubId,
		clubOwner
	} = useMemberDisplayContext()

	const { first, last, middle, prefix, suffix } = nameObj(
		unescapeText(member.name)
	)

	const boldLast = Boolean(last)
	const boldFirst = !boldLast && first

	const isOrg = isOrgSortMap(member)

	let name = <></>
	if (isOrg) {
		name = (
			<>
				<List.Header>
					<b>{member.org}</b>
				</List.Header>
			</>
		)
	} else {
		name = (
			<>
				<List.Header>
					{prefix ? <span>{prefix}&nbsp;</span> : null}
					{first ? (
						<span
							style={{
								fontWeight: boldFirst ? 'bold' : 'normal'
							}}
						>
							{first}&nbsp;
						</span>
					) : null}
					{middle ? <span>{middle}&nbsp;</span> : null}
					{last ? (
						<span
							style={{
								fontWeight: boldLast ? 'bold' : 'normal'
							}}
						>
							{last}&nbsp;
						</span>
					) : null}
					{suffix ? <span>{suffix}</span> : null}
				</List.Header>
				{member.org ? (
					<List.Description>
						{unescapeText(member.org.split(';')[0])}
					</List.Description>
				) : null}
			</>
		)
	}

	return (
		<List.Item
			onClick={() => onSelectMember(member.id)}
			className='member-list-item'
			id={selectedMember === member.id ? 'member-selected' : null}
		>
			<div className='member-bg'></div>
			{/* <Image>
				<Icon circular fitted name={isOrg ? 'building' : 'user'} />
			</Image> */}
			<MemberImage
				owner={clubOwner}
				clubId={clubId}
				memberId={member.id}
				thumbSize={25}
				size={27}
				iconOnFail={isOrg ? 'building' : 'user'}
				style={{ paddingRight: '0.5em' }}
			/>
			<List.Content className='member-name'>{name}</List.Content>
			<List.Content floated='right' verticalAlign='middle'>
				{member.email ? (
					<div className='email-label'>
						<Icon name='envelope outline' />
						{member.email}
					</div>
				) : null}
				<Icon name='chevron right' />
			</List.Content>
		</List.Item>
	)
}

export default MemberListItem
