import './PasswordView.less'

import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
	Button,
	Dimmer,
	Form,
	Header,
	Icon,
	Input,
	Loader,
	Modal,
	Segment,
	Sidebar
} from 'semantic-ui-react'

import FullPagePadder from '../../components/FullPagePadder'
import { useVisitorContext } from '../../hooks/VisitorAuthHook'

const PasswordView = ({ children }) => {
	const {
		clubUrl,
		authenticated,
		requestToken,
		joiningWithoutPass
	} = useVisitorContext()

	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const submitForm = async () => {
		setLoading(true)
		try {
			await requestToken(password)
		} catch (err) {
			setError(err)
			console.log(err)
		}
		setLoading(false)
	}

	const handleChange = e => {
		setPassword(e.target.value)
	}

	const visitorPassword = (
		<>
			<Dimmer inverted={isMobile} active={loading || joiningWithoutPass}>
				<Loader />
			</Dimmer>
			<div className={'visitor-password'}>
				{/* <Header className='password-header' content={clubUrl} /> */}
				<Form onSubmit={submitForm}>
					<Form.Input
						className='visitor-password-input'
						type='password'
						size='huge'
						placeholder='Password'
						onChange={handleChange}
						action
						fluid
						value={password}
						error={
							error
								? {
										content: error.message,
										pointing: 'below'
								  }
								: undefined
						}
					>
						<input />
						<Button basic type='submit'>
							OK
						</Button>
					</Form.Input>
				</Form>
			</div>
		</>
	)

	return (
		<div className='visitor-route'>
			<Modal open={!authenticated} centered={false}>
				<Modal.Content>{visitorPassword}</Modal.Content>
			</Modal>
			{authenticated ? (
				children
			) : (
				<FullPagePadder>
					<Icon name='lock' size='huge' disabled />
				</FullPagePadder>
			)}
		</div>
	)
}

export default PasswordView
