import 'firebase/firestore'
import 'firebase/auth'

import './App.less'

import * as firebase from 'firebase/app'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link, NavLink, Route, Switch } from 'react-router-dom'
import {
	Button,
	Dropdown,
	Header,
	Icon,
	Loader,
	Menu,
	Message,
	Segment,
	Sidebar
} from 'semantic-ui-react'

import { AppProvider, useAppContext } from '../hooks/AppHook'
import { useAuthContext } from '../hooks/AuthHook'
import Account from './app/Account'
import AppSplash from './app/AppSplash'
import CreateNewClub from './app/CreateNewClub'
import UserClub from './app/UserClub/UserClub'
import NotFound404 from './NotFound404'

const App = props => (
	<AppProvider>
		<div className='app' id={isMobile ? 'mobile' : 'desktop'}>
			<AppBody {...props} />
		</div>
	</AppProvider>
)
export default App

const VerifyEmail = () => {
	const user = firebase.auth().currentUser

	const [emailSent, setEmailSent] = useState(user.emailVerified)

	const resend = () => {
		user.sendEmailVerification()
		setEmailSent(true)
	}

	if (user.emailVerified) return null

	return (
		<Message warning icon>
			<Icon name='envelope' />

			<Message.Content>
				<Message.Header>You need to verify your email.</Message.Header>
				<p>
					You won't be able to create a club until your email address is
					verified. Check your email ({user.email}) for the verification link.
				</p>
				<p>
					{emailSent ? (
						'Verification email sent!'
					) : (
						<Button onClick={resend}>Resend verification email</Button>
					)}
				</p>
			</Message.Content>
		</Message>
	)
}

const AppBody = ({ match }) => {
	const { clubNameForId, userDoc } = useAppContext()

	const location = useLocation()

	// TODO consider reworking this, may break on route reworking.
	const selectedClubName = useCallback(() => {
		return clubNameForId(location.pathname.split('/')[3])
	}, [userDoc])()

	return (
		<div className='content'>
			<AppNav selectedClubName={selectedClubName} />
			<VerifyEmail />
			<Switch>
				<Route path={match.path + '/new'} exact component={CreateNewClub} />
				<Route path={match.path + '/account'} exact component={Account} />
				<Route
					// this order of paths matters.
					path={[
						match.path + '/club/:clubUrl/print',
						match.path + '/club/:clubUrl/settings',
						match.path + '/club/:clubUrl/confirm_delete/:deleteKey',
						match.path + '/club/:clubUrl/:memberId',
						match.path + '/club/:clubUrl'
					]}
					// exact
					component={UserClub}
				/>
				<Route path={match.path} exact component={AppSplash} />
				<Route component={NotFound404} />
			</Switch>
		</div>
	)
}
const AppNav = ({ selectedClubName }) => {
	const { user, signOut } = useAuthContext()

	const history = useHistory()
	const location = useLocation()

	const { userDoc, clubs, loading, error } = useAppContext()

	const signOutAndRedirect = async () => {
		await signOut()
		history.push('/')
	}

	const ClubItem = ({ url, name }) => {
		return (
			<Dropdown.Item
				key={url}
				onClick={() => history.push('/app/club/' + url)}
				active={location.pathname.includes(url)}
				content={name}
			/>
		)
	}

	return (
		<Menu secondary size='large' id='app-nav'>
			<Dropdown
				item
				text={selectedClubName || 'Select a Club'}
				pointing='top left'
				id='club-picker'
				// className={selectedClubName ? null : 'no-club-selected'}
			>
				<Dropdown.Menu>
					<Dropdown.Header content='My Clubs' />
					{loading ? (
						<Loader active inline='centered' />
					) : userDoc ? (
						Object.entries(userDoc.ownedClubs).map(([url, name]) => (
							<ClubItem key={url} url={url} name={name} />
						))
					) : (
						<Dropdown.Item disabled content='No Clubs' />
					)}
					<Dropdown.Divider />
					<Dropdown.Item
						icon='plus square outline'
						onClick={() => history.push('/app/new')}
						content='New Club'
					/>
				</Dropdown.Menu>
			</Dropdown>
			{/* <Menu.Item
				style={{
					flexBasis: 'max-content'
				}}
			></Menu.Item> */}

			<Menu.Menu position='right'>
				<Menu.Item
					onClick={() => {
						window.open('/docs', '_blank')
					}}
				>
					<Icon name='question' />
					Help
				</Menu.Item>

				<Dropdown item text='My Account' pointing='top right' icon={null}>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								history.push('/app/account')
							}}
							content='Settings'
							icon='settings'
						/>
						<Dropdown.Divider />
						<Dropdown.Item
							onClick={signOutAndRedirect}
							content='Log Out'
							icon='log out'
						/>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Menu>
		</Menu>
	)
}
