import 'firebase/functions'

import './LoginPanel.less'

import * as firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
	Button,
	Divider,
	Form,
	Icon,
	Message,
	Segment
} from 'semantic-ui-react'

import { useAuthContext } from '../hooks/AuthHook'

const LoginPanel = ({ register = false, ...props }) => {
	const {
		user,
		signInGoogle,
		registerEmail,
		signInEmail,
		signOut
	} = useAuthContext()

	const placeholder = new Array(10).fill('●').join('')

	const [email, setEmail] = useState('')
	const [pass, setPass] = useState('')
	const [passTwo, setPassTwo] = useState('')

	const [loading, setLoading] = useState(false)

	const [error, setError] = useState(null)

	const [signupsDisabled, setSignupsDisabled] = useState(false)

	useEffect(() => {
		firebase
			.functions()
			.httpsCallable('signupsDisabled')()
			.then(r => {
				setSignupsDisabled(r.data === 'true')
			})
	}, [])

	const handleChangeEmail = (e, { value }) => {
		if (error) setError(null)
		setEmail(value)
	}

	const handleChangePass = (e, { value }) => {
		if (error) setError(null)
		setPass(value)
	}

	const handleChangePassTwo = (e, { value }) => {
		if (error) setError(null)
		setPassTwo(value)
	}

	const handleLogin = async () => {
		if (error) setError(null)
		setLoading(true)
		try {
			await signInEmail(email, pass)
		} catch (err) {
			setError(err.message)
		}
		setLoading(false)
	}

	const handleRegister = async () => {
		if (error) setError(null)
		setLoading(true)
		if (pass !== passTwo) setError('Passwords must match.')
		try {
			await registerEmail(email, pass)
		} catch (err) {
			setError(err.message)
		}
		setLoading(false)
	}

	if (!!user)
		return (
			<Segment className={props.className + ' login-panel'} raised {...props}>
				<p>You're already logged in! ✔️</p>
				<Button size='large' onClick={signOut}>
					Log Out
				</Button>
			</Segment>
		)

	return (
		<Segment
			className={props.className + ' login-panel'}
			raised
			loading={loading}
			{...props}
		>
			<Form
				className='login-form'
				onSubmit={register ? handleRegister : handleLogin}
				error={error}
			>
				<Message error size='tiny' content={error} />
				<Form.Input
					size='huge'
					placeholder='Email'
					onChange={handleChangeEmail}
				>
					<input value={email} />
				</Form.Input>

				<Form.Input
					size='huge'
					type='password'
					placeholder={placeholder}
					onChange={handleChangePass}
				>
					<input value={pass} />
				</Form.Input>

				{register && (
					<Form.Input
						size='huge'
						type='password'
						placeholder={placeholder}
						onChange={handleChangePassTwo}
					>
						<input value={passTwo} />
					</Form.Input>
				)}
				<Form.Button
					primary
					size='large'
					type='submit'
					className='login-button'
				>
					{register ? 'Sign Up' : 'Log In'}
				</Form.Button>
			</Form>
			<div className='alt-links'>
				{register ? (
					<>
						<Link to='/login'>Log In</Link>
					</>
				) : (
					<>
						<Link to='/register'>Create An Account</Link>
						<Link to='/forgot-password'>Forgot Password?</Link>
					</>
				)}
			</div>
			<Divider horizontal>or</Divider>
			<div className='extra-buttons'>
				<Button onClick={signInGoogle}>
					<Icon name='google' />
					{register ? 'Sign Up With Google' : 'Google'}
				</Button>
			</div>

			{signupsDisabled && register && (
				<>
					<Divider />
					<section style={{ textAlign: 'center', color: 'gray' }}>
						We've disabled new signups at the moment. New accounts will be
						automatically disabled. Check back soon!
					</section>
				</>
			)}
		</Segment>
	)
}

export default LoginPanel
