import 'firebase/functions'

import * as firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import { Button, Header, Message, Modal } from 'semantic-ui-react'

import useInterval from '../../../util/useInterval'

const TICKER_TIME = 1000

/** @type {React.FC<{ club: ClubDocument, clubId: string }>} */
const SendMassEmail = ({ club, clubId, trigger }) => {
	const [sent, setSent] = useState(0)
	const [sentResult, setSentResult] = useState({})

	const sendMass = async () => {
		try {
			setSent(1)
			const res = await firebase.functions().httpsCallable('sendMassClubEmail')(
				{
					club: clubId
				}
			)
			console.log(res)
			setSent(2)
			setSentResult(res)
		} catch (err) {
			setSent(0)
			setSentResult({ error: err.message })
		}
	}

	const emailCount = Object.values(club.memberList).filter(m => !!m.email)
		.length

	const [ticker, setTicker] = useState(0)

	const interval = useInterval(() => {
		setTicker(t => t + 1)
	}, Math.max(1, TICKER_TIME / emailCount))

	useEffect(() => {
		if (ticker >= emailCount) {
			clearInterval(interval)
		}
	}, [ticker])

	return (
		<Modal.Content>
			<Header size='huge' textAlign='center'>
				<Header.Subheader>There are</Header.Subheader>
				{ticker}
				<Header.Subheader>
					Contacts with an Associated Email in your Club.
				</Header.Subheader>
			</Header>
			<Message visible>
				When you press the button below, each Associated Email you've added to
				your club will receive an email, containing a link to edit their own
				contact.
			</Message>
			{sentResult.error && (
				<Message error>
					There was an error sending messages: <pre>{sentResult.error}</pre>
				</Message>
			)}
			{sentResult.rejected > 1 && (
				<Message error>
					<b>{sentResult.rejected}</b> emails failed to send.
				</Message>
			)}
			<Button.Group fluid size='huge'>
				<Button
					positive={sent != 1}
					onClick={sent == 0 ? sendMass : null}
					disabled={sent != 0}
					loading={sent == 1}
				>
					{[`Send ${emailCount} Emails`, `Sending...`, `Sent!`][sent]}
				</Button>
				{(sent == 0) & <Button>Cancel</Button>}
			</Button.Group>
		</Modal.Content>
	)
}

export default SendMassEmail
