import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'
import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Property } from 'vcfer'

import { toCapitalized, unescapeText } from '../../util'
import { useViewMemberContext } from './ViewMember'

/** @type {React.FC<{
    prop: string,
    name: string,
    component: string,
    showType: boolean
  }>} */
const ViewPropHandler = ({ prop: _prop, name, component, showType = true }) => {
	const { card } = useViewMemberContext()

	if (!card.has(_prop)) return null

	const props = card.get(_prop).filter(prop => !!prop.value)

	if (props.length == 0) return null

	const HandlerComponent = propHandlerComponents[component || _prop]

	return (
		<div className='view-prop-handler'>
			<div className='view-prop-handler-header'>{name}</div>
			{props.map((prop, i) => {
				const type = prop.params['type']
				return (
					<div className='view-prop-handler-component-wrap' key={i}>
						{showType && type && (
							<div className='view-prop-handler-type'>
								{toCapitalized(typeof type === 'string' ? type : type[0])}
							</div>
						)}
						<div className='view-prop-handler-component'>
							<HandlerComponent prop={prop} showType={showType} />
						</div>
					</div>
				)
			})}
		</div>
	)
}

/**
 * @type {{[name: string]: React.FC<{prop: Property}>}}
 */
export const propHandlerComponents = {
	generic: ({ prop }) => {
		return <p>{unescapeText(prop.value)}</p>
	},
	tel: ({ prop }) => {
		const phoneNumber = parsePhoneNumberFromString(prop.value, 'US')
		if (!phoneNumber) return null

		return (
			<a href={phoneNumber.getURI()}>
				{phoneNumber.country === 'US'
					? phoneNumber.formatNational()
					: phoneNumber.formatInternational()}
			</a>
		)
	},
	email: ({ prop }) => (<a href={'mailto:' + prop.value}>{prop.value}</a>),
	url: ({ prop }) => (
		<a href={prop.value} target='_blank' rel='noopener noreferrer'>
			{prop.value}
		</a>
	),
	adr: ({ prop }) => {
		const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
			prop.value.split(';').join(' ')
		)}`

		const lines = prop.value.split(';').filter(l => !!l)

		return (
			<a href={url} target='_blank' rel='noopener noreferrer'>
				{lines.map((line, i) => {
					return <p key={i}>{line}</p>
				})}
			</a>
		)
	},
	gender: ({ prop }) => {
		const [sex, gender] = prop.value.split(';')
		const [sexString, icon] = {
			M: ['Male', 'mars'],
			F: ['Female', 'venus'],
			O: ['Other', 'transgender-alt'],
			U: ['Unknown', 'genderless'],
			'': ['None', 'genderless']
		}[sex]
		return (
			<p>
				<Icon name={icon} size='big' />
				<span>
					{sexString ? sexString : null}
					{sexString && gender ? ', ' : null}
					{gender}
				</span>
			</p>
		)
	},
	note: ({ prop }) => {
		return <p style={{ whiteSpace: 'pre-wrap' }}>{unescapeText(prop.value)}</p>
	},
	nickname: ({ prop }) => <p>"{unescapeText(prop.value)}"</p>,
	date: ({ prop }) => (
		<p>{moment(prop.value, 'YYYYMMDD').format('MMMM Do, YYYY')}</p>
	)
}

export default ViewPropHandler
