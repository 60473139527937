import './EditorView.less'

import React, { useEffect, useMemo, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import VCard from 'vcfer'

import EditMember from '../../components/EditMember2/EditMember'
import { useVisitorContext } from '../../hooks/VisitorAuthHook'
import { fileToBase64 } from '../../util'
import usePageTitle from '../../util/usePageTitle'

const EditorView = ({ match }) => {
	/** @type {string} */
	const editUrl = match.params.editUrl

	const {
		authenticated,
		useApiData,
		clubApiCall,
		clubUrl
	} = useVisitorContext()

	const [member, loading, error] = useApiData(`/edit/${editUrl}`)

	// const [member, setMember] = useState(null)

	// useEffect(() => {
	// 	if (!loadedMember) return
	// 	setMember(loadedMember)
	// }, [loadedMember])

	// const card = useMemo(() => {
	// 	console.log(member)
	// 	if (!member) return null
	// 	const c = new VCard(JSON.parse(member.card))
	// 	console.log(c)
	// 	return c
	// }, [member])

	usePageTitle('Editing', [])

	const handleSubmit = async (data, setSubmitStatus, setSubmitError) => {
		setSubmitStatus('submitting')

		try {
			const body = {
				...data
			}
			const res = await clubApiCall(
				'POST',
				`/edit/${editUrl}`,
				JSON.stringify(body)
			)
			console.log(res)
			if (res.success) {
				setSubmitStatus('submitted')
			} else {
				setSubmitStatus('error')
				setSubmitError(true)
			}
		} catch (err) {
			setSubmitStatus('error')
			setSubmitError(true)
			console.error(err)
		}
	}

	/**
	 *
	 * @param {File} file
	 * @param {*} owner
	 * @param {string} clubId
	 * @param {string} memberId
	 */
	const uploadMemberImage = async (file, owner, clubId, memberId) => {
		const fileData = await fileToBase64(file)

		const res = await clubApiCall(
			'POST',
			`/edit/${editUrl}/upload`,
			JSON.stringify({ image: fileData })
		)

		if (!res.status == 200)
			throw new Error('Image upload failed, status code:', res.statusText)

		return res
	}

	const deleteMemberImage = async (clubId, memberId) => {
		const res = await clubApiCall('POST', `/edit/${editUrl}/delete`)

		if (!res.status == 200)
			throw new Error('Image delete failed, status code:', res.statusText)

		return res
	}

	return (
		<div className='editor-view'>
			{loading || !member ? (
				<Loader />
			) : (
				<EditMember
					clubId={clubUrl}
					member={member}
					handleSubmit={handleSubmit}
					className='editor-member'
					showUpload={false}
					uploadMemberImage={uploadMemberImage}
					deleteMemberImage={deleteMemberImage}
					editorVersion={true}
				/>
			)}
		</div>
	)
}

export default EditorView
