import { useEffect } from 'react'

/**
 * Changes the page title to the format "Circlus - <title>"
 * @param {string | function} title
 * @param {any[]} dependencies
 */
const usePageTitle = (title, dependencies) => {
	useEffect(() => {
		if (!title) {
			document.title = 'Circlus'
		} else if (typeof title === 'string') {
			document.title = title + ' - Circlus'
		} else if (typeof title === 'function') {
			document.title = title() + ' - Circlus'
		}
	}, dependencies)
}

export default usePageTitle
