import './ViewMember.less'

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Header, Image } from 'semantic-ui-react'
import VCard from 'vcfer'

import { memberImageUrl } from '../../util'
import vUtil from '../../util/vutil'
import CodeBlock from '../CodeBlock'
import QuickIcon from './QuickIcon'
import ViewPropHandler from './ViewPropHandler'

export const ViewMemberContext = React.createContext({
	card: new VCard()
})

export const useViewMemberContext = () => React.useContext(ViewMemberContext)

/** @type {React.FC<{
    member: MemberDocumentWithId,
    clubId: string
    className: string
  }>} */
const ViewMember = ({ member, clubId, className }) => {
	const card = new VCard(JSON.parse(member.card))

	console.log('isOrg: ', vUtil(card).isOrg())

	const quickIcons = (
		<>
			<QuickIcon
				icon='phone'
				propName='tel'
				onClick={prop => {
					const phoneNumber = parsePhoneNumberFromString(prop.value, 'US')
					window.open(phoneNumber.getURI())
				}}
			/>
			<QuickIcon
				icon='chat'
				propName='tel'
				onClick={prop => {
					const phoneNumber = parsePhoneNumberFromString(prop.value, 'US')
					window.open('sms:' + phoneNumber.getURI().split(':')[1])
				}}
			/>
			<QuickIcon
				icon='envelope'
				propName='email'
				onClick={prop => {
					window.open('mailto:' + prop.value)
				}}
			/>
		</>
	)

	const propHandlers = (
		<>
			<ViewPropHandler prop='nickname' name='Nickname' showType={false} />
			<ViewPropHandler prop='gender' name='Gender' showType={false} />
			<ViewPropHandler prop='tel' name='Phone' />
			<ViewPropHandler prop='email' name='Email' />
			<ViewPropHandler prop='adr' name='Address' />
			<ViewPropHandler
				prop='xSocialProfile'
				name='Social Profile'
				component='generic'
			/>
			<ViewPropHandler prop='impp' name='Messenger' component='generic' />
			<ViewPropHandler
				prop='bday'
				name='Birthday'
				showType={false}
				component='date'
			/>
			<ViewPropHandler
				prop='anniversary'
				name='Anniversary'
				showType={false}
				component='date'
			/>
			<ViewPropHandler prop='note' name='Note' showType={false} />
		</>
	)

	return (
		<ViewMemberContext.Provider value={{ card }}>
			<div className={className} id='loaded-member'>
				<Image
					src={memberImageUrl(member.owner, clubId, member.id)}
					centered
					circular
					width='150'
					height='150'
					onError={e => (e.target.src = '/avatar.jpg')}
				/>

				<Header textAlign='center' size='huge' className='member-title'>
					{vUtil(card).title()}
				</Header>
				{!vUtil(card).isOrg() && card.has('org') && (
					<Header textAlign='center' size='large' className='member-sub-header'>
						{card.get('org')[0].value}
					</Header>
				)}
				{card.has('title') && (
					<Header textAlign='center' className='member-sub-header'>
						{card.get('title')[0].value}
					</Header>
				)}
				<div className='quick-icons'>{quickIcons}</div>
				<div className='prop-handlers'>{propHandlers}</div>
				<CodeBlock>{card.toString()}</CodeBlock>
			</div>
		</ViewMemberContext.Provider>
	)
}

export default ViewMember
