import 'firebase/functions'
import 'firebase/auth'

import * as firebase from 'firebase/app'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
	Card,
	Dimmer,
	Form,
	Grid,
	Header,
	Icon,
	Loader,
	Message
} from 'semantic-ui-react'

import { JSONCodeBlock } from '../../components/CodeBlock'
import SettingsMenu from '../../components/SettingsMenu'
import { useAppContext } from '../../hooks/AppHook'
import { stripe } from '../../util'
import usePageTitle from '../../util/usePageTitle'

const Account = () => {
	const pages = {
		'Account Info': MyAccount,
		'Payment Info': PaymentInfo
	}

	const { userDoc } = useAppContext()

	usePageTitle('My Account', [])

	return (
		<>
			<SettingsMenu
				header={'My Account'}
				pages={pages}
				defaultPage='Account Info'
				data={{ userDoc }}
			/>
			<div id='storefront-footer'>
				<span>
					Problems? Bugs? <a href='mailto:support@circl.us'>Email Support</a>
				</span>
				<span>
					<Link to='/faq'>FAQ</Link>
				</span>
			</div>
		</>
	)
}

const MyAccount = ({ userDoc }) => {
	const [password1, setPassword1] = useState('')
	const [password2, setPassword2] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const [changingPassword, setChangingPassword] = useState(false)

	const handleSubmit = async () => {
		try {
			setPasswordError('')
			setChangingPassword(true)
			if (password1 !== password2) throw new Error('Passwords do not match.')
			await firebase.auth().currentUser.updatePassword(password1)
			setPassword1('')
			setPassword2('')
		} catch (err) {
			setPasswordError(err.message)
		}
		setChangingPassword(false)
	}

	return (
		<>
			<p style={{ color: 'gray' }}>
				Account UID: <code>{userDoc ? userDoc.uid : 'Loading...'}</code>
			</p>
			<Header as='h3'>Change Password</Header>
			<Form
				onSubmit={handleSubmit}
				error={passwordError}
				loading={changingPassword}
			>
				<Message error content={passwordError} />
				<Form.Input
					type='password'
					state={password1}
					onChange={e => setPassword1(e.target.value)}
					label='New Password'
				/>
				<Form.Input
					type='password'
					state={password2}
					onChange={e => setPassword2(e.target.value)}
					label='Confirm New Password'
				/>
				<Form.Button type='submit'>Change Password</Form.Button>
			</Form>

			<JSONCodeBlock json={userDoc} />
		</>
	)
}

const PaymentInfo = ({ userDoc }) => {
	/**
	 * @type {[import('stripe').customers.ICustomer, *]}
	 */
	const [customer, setCustomer] = useState(null)

	useEffect(() => {
		firebase
			.functions()
			.httpsCallable('myCustomerInfo')()
			.then(res => {
				console.log(res.data.customer)
				setCustomer(res.data.customer)
			})
	}, [])

	if (!customer)
		return (
			<Dimmer active inverted>
				<Loader />
			</Dimmer>
		)
	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column>Email</Grid.Column>
					<Grid.Column>{customer.email}</Grid.Column>
				</Grid.Row>
			</Grid>
			<Header as='h2'>Subscriptions</Header>
			<Card.Group itemsPerRow={3}>
				{customer.subscriptions.data.map(subscription => (
					<SubscriptionCard subscription={subscription} key={subscription.id} />
				))}
			</Card.Group>
			<Header as='h2'>Saved Payment Methods</Header>
			<Card.Group itemsPerRow={3}>
				{customer.sources.data.map(source => (
					<PaymentSourceCard
						source={source}
						subscriptions={customer.subscriptions.data}
					/>
				))}
			</Card.Group>
			<JSONCodeBlock json={customer} />
		</>
	)
}

const cardIcons = {
	Visa: 'cc visa',
	'American Express': 'cc amex',
	MasterCard: 'cc mastercard',
	Discover: 'cc discover',
	JCB: 'cc jcb',
	'Diners Club': 'cc diners club',
	Unknown: 'cc stripe'
}

const cardCvcStatuses = {
	pass: '✔️',
	fail: "❌ You need to re-enter this card's verification code.",
	unavailable: "❌ This card's CVC is unavailable.",
	unchecked: "❔ This card's CVC hasn't been checked."
}

/**
 *
 * @param {object} params
 * @param {import('stripe').sources.ISource} params.source
 * @param {import('stripe').subscriptions.ISubscription[]} params.subscriptions
 */
const PaymentSourceCard = ({ source, subscriptions }) => {
	/** @type {import('stripe').cards.ICard} */
	const card = source.object === 'card' ? source : source.card

	return (
		<Card>
			<Card.Content>
				<Header size='tiny' floated='right'>
					✔️
				</Header>
				<Card.Header>
					<Icon name={cardIcons[card.brand]} />
					{card.brand}
				</Card.Header>
				<Card.Meta>
					<code>**** **** **** {card.last4}</code>
				</Card.Meta>
			</Card.Content>
		</Card>
	)
}

/**
 *
 * @param {object} params
 * @param {import('stripe').subscriptions.ISubscription} params.subscription
 */
const SubscriptionCard = ({ subscription }) => {
	const m = moment.unix(subscription.current_period_end)

	const clubId = subscription.metadata.clubId

	const history = useHistory()

	return (
		<Card href={`/app/club/${clubId}`}>
			<Card.Content>
				<Card.Header>{subscription.metadata.name}</Card.Header>
				<Card.Meta>
					Renews in {m.diff(moment(), 'days')} days ({m.format('MMMM Do')})
				</Card.Meta>
			</Card.Content>
		</Card>
	)
}

export default Account
